import React from "react";
import EntrySectionCard from "../../Cards/EntrySectionCard";
import { Event } from "../../../models";
import { formatStartEndDatesStrings } from "../../../utilities/dates/FormatDates";
import { ExpandedCardStates } from "../../../pages/dashboard/events/EventNewEntryPage";

interface EntryPageHeaderProps {
    event: Event;
    expandedCard: ExpandedCardStates;
    handleCollapseOrExpand: (card: ExpandedCardStates) => (isExpand: boolean) => void;
    navigateToNextCard: () => void;
}

const EntryPageHeader: React.FC<EntryPageHeaderProps> = ({
    event,
    expandedCard,
    handleCollapseOrExpand,
    navigateToNextCard
}) => {
    const startDate = event.startDate;
    const endDate = event.endDate;
    const dateRange = startDate && endDate ? `${formatStartEndDatesStrings(startDate, endDate, "MMM D, YYYY")}` : `${startDate}`;

    return (
        <EntrySectionCard
            event={event}
            miniTitle={dateRange}
            title={event.name}
            chipTitle="Event Info"
            trashIcon={false}
            expandButtonTitle="View"
            isExpand={expandedCard === "header"}
            cardContent={expandedCard}
            toggleExpansion={handleCollapseOrExpand("header")}
            onSubmit={navigateToNextCard}
        />
    );
};

export default EntryPageHeader;
