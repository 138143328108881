import React, { Fragment, useEffect, useState } from "react";
import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCardSubtitle,
    IonRow,
    IonIcon,
    IonCol,
} from "@ionic/react";
import { call, earthSharp, locationSharp, closeCircle } from "ionicons/icons";
import { Event, Contact } from "../../../models";
import { formatAddress } from "../../../utilities/address/FormatAddress";
import { getContactById } from "../../../utilities/contact/Contact";
import Spinner from "../../Spinners/Spinner";
import { formatPhoneNumberForDisplay } from "../../../utilities/contact/FormatPhoneNumber";

interface EventInfoSectionProps {
    event: Event;
}

const EventInfoSection: React.FC<EventInfoSectionProps> = ({ event }) => {
    const [eventContacts, setEventContacts] = useState<Contact[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    async function getContact(contactId: string): Promise<Contact | undefined> {
        const queryResult = await getContactById(contactId);
        if (queryResult.isSuccess) {
            return queryResult.result;
        }
    }
    
    async function getEventContacts(contactIds: (string | null)[]) {
        setIsLoading(true);
        const contactArray: Contact[] = [];
        for (const contactId of contactIds) {
            if (contactId) {
                const contact = await getContact(contactId);
                if (contact) contactArray.push(contact);
            }
        }
        setEventContacts(contactArray);
        setIsLoading(false);
    }

    useEffect(() => {
        if (event?.contactIds) {
            getEventContacts(event.contactIds);
        } else if (event?.contactId) {
            getEventContacts([event.contactId]);
        }
    }, [event]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <IonCard className="rounded-3 bg-white p-0 m-0 mb-2">
            <IonCardHeader className="pb-0">
                <IonCardSubtitle className="display-5.5 text-uppercase font-weight-bold text-dark">
                    Management Contacts
                </IonCardSubtitle>
                <IonCardTitle className="display-3.5 font-weight-700 text-primary lh-100 m-0 pt-1">
                    {event?.name || "Event Location Name"}
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="p-2 pb-3">
                <IonRow className="ion-align-items-left p-0">
                    <IonCol sizeXs="2" sizeMd="1" className="d-flex ion-justify-content-center card-icon-size-2 text-primary p-0">
                        <IonIcon icon={locationSharp} />
                    </IonCol>
                    <IonCol sizeXs="10" sizeMd="11" className="ion-justify-content-left display-4 text-primary m-0 p-0">
                        <p>
                            {event?.address ? formatAddress(event.address) : "Not Provided"}
                        </p>
                    </IonCol>
                </IonRow>
                {eventContacts.length > 0 ? (
                    eventContacts.map((contact, index) => (
                        <Fragment key={index}>
                            <IonRow className="ion-align-items-center display-4 text-primary p-0">
                                <IonCol sizeXs="2" sizeMd="1" className="d-flex ion-justify-content-center card-icon-size-2 text-primary p-0">
                                    <IonIcon icon={call} />
                                </IonCol>
                                <IonCol sizeXs="10" sizeMd="11" className="ion-justify-content-left display-4 text-primary m-0 p-0">
                                    <p>
                                        {contact.name || ""}{" "}
                                        {formatPhoneNumberForDisplay(contact.work) || "Phone Number Not Provided"}
                                    </p>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-align-items-center display-4 text-primary p-0">
                                <IonCol sizeXs="2" sizeMd="1" className="d-flex ion-justify-content-center card-icon-size-2 text-primary p-0">
                                    <IonIcon icon={earthSharp} />
                                </IonCol>
                                <IonCol sizeXs="10" sizeMd="11" className="ion-justify-content-left display-4 text-primary m-0 p-0">
                                    {contact.website ? (
                                        <a
                                            href={contact.website}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {contact.website}
                                        </a>
                                    ) : (
                                        <p>Website Not Provided</p>
                                    )}
                                </IonCol>
                            </IonRow>
                        </Fragment>
                    ))
                ) : (
                    <IonRow className="ion-align-items-center display-4 text-primary p-0">
                        <IonCol sizeXs="2" sizeMd="1" className="d-flex ion-justify-content-center card-icon-size-2 text-primary p-0">
                            <IonIcon icon={closeCircle} />
                        </IonCol>
                        <IonCol sizeXs="10" sizeMd="11" className="ion-justify-content-left m-0 p-0">
                            <p>No Contact Info Provided</p>
                        </IonCol>
                    </IonRow>
                )}
            </IonCardContent>
        </IonCard>
    );
};

export default EventInfoSection;
