export const listBasicEventClasss = /* GraphQL */ `
  query ListEventClasss(
    $filter: ModelEventClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventClasss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        eventId
        classId
        eventDivisionId
        organizationClassId
        organizationDivisionId
        type
        number
        entryFee
        prizeMoney
        currentNumberEntries
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        jumpHeight
        isScheduled
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;

export const eventClassesByEventId = /* GraphQL */ `
  query EventClassesByEventId(
    $eventId: ID!
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassesByEventId(
      eventId: $eventId
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          divisionId
          division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
          organizationDivisionId
          entryFee
          prizeMoney
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationClassId
        organizationDivisionId
        type
        number
        entryFee
        prizeMoney
        pointTable {
          id
          name
          description
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;

export const eventClassesByEventIdWithResults = /* GraphQL */ `
  query EventClassesByEventId(
    $eventId: ID!
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassesByEventId(
      eventId: $eventId
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        eventId
        classId
        eventDivisionId
        organizationClassId
        organizationDivisionId
        eventResults {
          items {
            createdBy
            id
            place
            eventClassEntryId
          }
          nextToken
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        number
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;

export const eventClassesByEventIdWithFullEventDivisions = /* GraphQL */ `
  query EventClassesByEventIdWithFullEventDivisions(
    $eventId: ID!
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassesByEventId(
      eventId: $eventId
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        eventId
        classId
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          organizationDivision {
            id 
            name
            number
          }
          entryFee
          eventClasses {
            items {
              id
              name
              number
              type
            }
            nextToken
          }
          prizeMoney
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationClassId
        organizationDivisionId
        type
        number
        entryFee
        prizeMoney
        pointTable {
          id
          name
          description
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;