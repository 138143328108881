import React, { useMemo, useState } from "react";
import {
  IonList,
  IonItemGroup,
  IonLabel,
  IonCheckbox,
  IonText,
  IonIcon,
  IonCol,
  IonRow,
} from "@ionic/react";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import { formatDateLongFormat } from "../../utilities/dates/FormatDates";
import { formattedOptionGroup, formattedOption } from "./ClassInfoSection";

interface ClassListProps {
  formattedOptionGroups: formattedOptionGroup[];
  onChange: Function;
  searchText: string;
}

const ClassList: React.FC<ClassListProps> = ({ formattedOptionGroups, searchText, onChange }) => {
  // Base groups to use when there is no search text available. Will track which divisions have been expanded and which items have been checked.
  const [baseFormattedOptionGroups, setBaseFormattedOptionGroups] = useState<formattedOptionGroup[] | null | undefined>();

  // Filters the base array based on the current search text. Should keep data in sync with the baseFormattedOptionGroups.
  const [filteredFormattedOptionGroups, setFilteredFormattedOptionGroups] = useState<formattedOptionGroup[] | null | undefined>();

  useMemo(() => {
    // Does not re-run unless the formattedOptionGroups prop changes
    setBaseFormattedOptionGroups(formattedOptionGroups);
    setFilteredFormattedOptionGroups(formattedOptionGroups);
  }, [formattedOptionGroups]);

  useMemo(() => {
    const useSearchTextToFilterOptions = (input: string) => {

      // If search input is empty, reset the filtered array to match the base array
      if (!input || input === "") {
        if (baseFormattedOptionGroups && baseFormattedOptionGroups.length > 0) {
          setFilteredFormattedOptionGroups(baseFormattedOptionGroups);
        }
        return;
      }
      
      // Track the formattedOptionGroups that match the searchText
      let result: formattedOptionGroup[] = []; 

      // Test if the searchText is a name or a number
      const regexResult = /^-?\d+$/.test(input);

      // If regexResult is true, then the input is a number - search by class or division number
      if (regexResult) {
        if (baseFormattedOptionGroups) {
            for (let i = 0; i < baseFormattedOptionGroups.length; i++) {
                const current = baseFormattedOptionGroups[i];
                if (current.class && current.class.object && current.class.object.number) {
                    if (current.class.object.number.toString().includes(input)) {
                        result.push(current);
                    }
                } else if (current.classes) {
                    for (let j = 0; j < current.classes.length; j++) {
                        const currentClass = current.classes[j];
                        if (currentClass && currentClass.object && currentClass.object.number) {
                            if (currentClass.object.number.toString().includes(input)) {
                                if (current.division) {
                                  // Try to expand the division so that this class that matches the number will be shown
                                  const updatedDivision: formattedOption = {
                                    ...current.division,
                                    isExpanded: true
                                  };
                                  const updatedOptionGroup = {
                                    ...current,
                                    division: updatedDivision
                                  };
                                  result.push(updatedOptionGroup);
                                } else {
                                  result.push(current);
                                }
                            }
                        }
                    }
                }
            }
            setFilteredFormattedOptionGroups(result);
        }
      } else {
        // Search by class or division name
          if (baseFormattedOptionGroups) {
              for (let i = 0; i < baseFormattedOptionGroups.length; i++) {
                  const current = baseFormattedOptionGroups[i];
                  if (current.division && current.division.name.toLowerCase().includes(input.toLowerCase())) {
                    // Matched divisions should be expanded
                    const updatedDivision: formattedOption = {
                      ...current.division,
                      isExpanded: true
                    };
                    const updatedOptionGroup = {
                      ...current,
                      division: updatedDivision
                    };
                    result.push(updatedOptionGroup);
                  }
                  if (current.class && current.class.name.toLowerCase().includes(input.toLowerCase())) result.push(current);
              }
              setFilteredFormattedOptionGroups(result);
          }
      }
    };

    // Does not re-run unless the searchText prop changes
    useSearchTextToFilterOptions(searchText);
  }, [searchText]);

  const toggleDivisionExpansion = (optionIndex: number) => {
    if (filteredFormattedOptionGroups && filteredFormattedOptionGroups.length > 0) {
      const foundOptionGroup = filteredFormattedOptionGroups[optionIndex];

      // Set up an optionGroup that will be updated as needed and then reset in State.
      let updatedOptionGroup = foundOptionGroup;
  
      // Clicked on a division, update the division booleans and any classes
      const foundDivision = foundOptionGroup.division;
      if (foundDivision) {
        const updatedDivision: formattedOption = {
          ...foundDivision,
          isExpanded: !(foundDivision.isExpanded),
        };
        updatedOptionGroup.division = updatedDivision;
      }

      // Update the filtered array
      const updatedFilteredFormattedOptionGroups = [
        ...filteredFormattedOptionGroups.slice(0, optionIndex),
        updatedOptionGroup,
        ...filteredFormattedOptionGroups.slice(optionIndex + 1)
      ];
      setFilteredFormattedOptionGroups(updatedFilteredFormattedOptionGroups);

      // Update the base array so that if the searchText changes, the updates will be preserved
      if (baseFormattedOptionGroups && baseFormattedOptionGroups.length > 0) {
        const foundIndexInBaseArray = baseFormattedOptionGroups?.findIndex((item) => item.division?.value === foundOptionGroup.division?.value);
        if (foundIndexInBaseArray > -1) {
          const updatedBaseFormattedOptionGroups = [
            ...baseFormattedOptionGroups.slice(0, foundIndexInBaseArray),
            updatedOptionGroup,
            ...baseFormattedOptionGroups.slice(foundIndexInBaseArray + 1)
          ];
          setBaseFormattedOptionGroups(updatedBaseFormattedOptionGroups);
        }
      }
    } else {
      console.log("No class or division options found.");
    }
  };

  const toggleDivisionCheckbox = (optionIndex: number, isChecked: boolean) => {
    if (filteredFormattedOptionGroups && filteredFormattedOptionGroups.length > 0) {
      const foundOptionGroup = filteredFormattedOptionGroups[optionIndex];

      // Set up an optionGroup that will be updated as needed and then reset in State.
      let updatedOptionGroup = foundOptionGroup;
  
      // Clicked on a division, update the division booleans and any classes
      const foundDivision = foundOptionGroup.division;
      if (foundDivision) {
        const updatedDivision: formattedOption = {
          ...foundDivision,
          isExpanded: true,
          isChecked: isChecked
        };
        updatedOptionGroup.division = updatedDivision;
      }

      if (updatedOptionGroup.classes) {
        let updatedClasses: formattedOption[] = [];
        for (let i = 0; i < updatedOptionGroup.classes.length; i++) {
          const oldClassOption = updatedOptionGroup.classes[i];
          const updatedClassOption = {...oldClassOption, isChecked: isChecked};
          updatedClasses.push(updatedClassOption);
        }
        updatedOptionGroup.classes = updatedClasses;
      }

      const updatedFilteredFormattedOptionGroups = [
        ...filteredFormattedOptionGroups.slice(0, optionIndex),
        updatedOptionGroup,
        ...filteredFormattedOptionGroups.slice(optionIndex + 1)
      ];
      setFilteredFormattedOptionGroups(updatedFilteredFormattedOptionGroups);

      // Update the base array so that if the searchText changes, the updates will be preserved
      if (baseFormattedOptionGroups && baseFormattedOptionGroups.length > 0) {
        const foundIndexInBaseArray = baseFormattedOptionGroups?.findIndex((item) => item.division?.value === foundOptionGroup.division?.value);
        if (foundIndexInBaseArray > -1) {

          const updatedBaseFormattedOptionGroups = [
            ...baseFormattedOptionGroups.slice(0, foundIndexInBaseArray),
            updatedOptionGroup,
            ...baseFormattedOptionGroups.slice(foundIndexInBaseArray + 1)
          ];
          setBaseFormattedOptionGroups(updatedBaseFormattedOptionGroups);
          onChange(updatedBaseFormattedOptionGroups);
        }
      }
    } else {
      console.log("No class or division options found.");
    }
  };

  const toggleDivisionClassCheckbox = (optionIndex: number, classIndex: number, isChecked: boolean) => {
    if (filteredFormattedOptionGroups && filteredFormattedOptionGroups.length > 0) {
      const foundOptionGroup = filteredFormattedOptionGroups[optionIndex];

      // Set up an optionGroup that will be updated as needed and then reset in State.
      let updatedOptionGroup = foundOptionGroup;
  
      // Clicked on a division class, update the division just that class
      if (updatedOptionGroup.classes) {
        let foundClass = updatedOptionGroup.classes[classIndex];
        if (foundClass) {

          // Check if the foundOptionGroup is already set to the required value
          if (foundClass.isChecked !== isChecked) {
            // This divisionClass was toggeled by the user and needs to be set
            const updatedClass = {
              ...foundClass,
              isChecked: isChecked
            };
            const updatedClasses = [
              ...updatedOptionGroup.classes.slice(0, classIndex),
              updatedClass,
              ...updatedOptionGroup.classes.slice(classIndex + 1)
            ];
            updatedOptionGroup.classes = updatedClasses;
            const updatedFilteredFormattedOptionGroups = [
              ...filteredFormattedOptionGroups.slice(0, optionIndex),
              updatedOptionGroup,
              ...filteredFormattedOptionGroups.slice(optionIndex + 1)
            ];
        
            setFilteredFormattedOptionGroups(updatedFilteredFormattedOptionGroups);

            // Update the base array so that if the searchText changes, the updates will be preserved
            if (baseFormattedOptionGroups && baseFormattedOptionGroups.length > 0) {
              const foundIndexInBaseArray = baseFormattedOptionGroups?.findIndex((item) => item.division?.value === foundOptionGroup.division?.value);
              if (foundIndexInBaseArray > -1) {

                const updatedBaseFormattedOptionGroups = [
                  ...baseFormattedOptionGroups.slice(0, foundIndexInBaseArray),
                  updatedOptionGroup,
                  ...baseFormattedOptionGroups.slice(foundIndexInBaseArray + 1)
                ];
                setBaseFormattedOptionGroups(updatedBaseFormattedOptionGroups);
                onChange(updatedBaseFormattedOptionGroups);
              }
            }
          } else {
            // This divisionClass was updated when the user toggled on the division it belongs to - no need to reset it again
          }
        };
      }
    } else {
      console.log("No class or division options found.");
    }
  };

  const toggleClassCheckbox = (optionIndex: number, isChecked: boolean) => {
    if (filteredFormattedOptionGroups && filteredFormattedOptionGroups.length > 0) {
      const foundOptionGroup = filteredFormattedOptionGroups[optionIndex];

      // Set up an optionGroup that will be updated as needed and then reset in State.
      let updatedOptionGroup = foundOptionGroup;
  
      // Clicked on a stand-alone class, update the class checked boolean
      const foundClass = foundOptionGroup.class;
      if (foundClass) {
        const updatedClass: formattedOption = {
          ...foundClass,
          isChecked: isChecked
        };
        updatedOptionGroup.class = updatedClass;
      }

      const updatedFilteredFormattedOptionGroups = [
        ...filteredFormattedOptionGroups.slice(0, optionIndex),
        updatedOptionGroup,
        ...filteredFormattedOptionGroups.slice(optionIndex + 1)
      ];
      setFilteredFormattedOptionGroups(updatedFilteredFormattedOptionGroups);

      // Update the base array so that if the searchText changes, the updates will be preserved
      if (baseFormattedOptionGroups && baseFormattedOptionGroups.length > 0) {
        const foundIndexInBaseArray = baseFormattedOptionGroups?.findIndex((item) => item.class?.value === foundOptionGroup.class?.value);
        if (foundIndexInBaseArray > -1) {
          const updatedBaseFormattedOptionGroups = [
            ...baseFormattedOptionGroups.slice(0, foundIndexInBaseArray),
            updatedOptionGroup,
            ...baseFormattedOptionGroups.slice(foundIndexInBaseArray + 1)
          ];
          setBaseFormattedOptionGroups(updatedBaseFormattedOptionGroups);
          onChange(updatedBaseFormattedOptionGroups);
        }
      }
    } else {
      console.log("No class or division options found.");
    }
  };

  return (
    <IonRow className="m-0 p-0 align-items-center small-scrollable">
      <IonCol className="p-0">
        {(filteredFormattedOptionGroups) ?
          <IonList className="p-0">
            {filteredFormattedOptionGroups.map((optionGroup, optionGroupIndex) => (
              <IonItemGroup key={optionGroupIndex}>
                {/* First, check if there is a division with a group of classes */}
                {optionGroup.division && (
                  <>
                    <IonRow className="justify-content-between align-items-center border-top bg-lighter px-2 tiny-scrollable">
                      <IonCol sizeSm="11" sizeXs="10" className="ion-text-left pl-0">
                        <IonRow className="align-items-center">
                          <IonCol 
                            sizeSm="1" 
                            sizeXs="2" 
                            className="pl-0" 
                            onClick={() => toggleDivisionCheckbox(optionGroupIndex, optionGroup.division?.isChecked ? false : true)}
                          >
                            <IonCheckbox
                              checked={optionGroup.division.isChecked}
                              // Causing issues when options are filtered
                              // onIonChange={(e) => {
                              //   toggleDivisionCheckbox(optionGroupIndex, e.detail.checked);
                              // }}
                            />
                          </IonCol>
                          <IonCol
                            sizeSm="11"
                            sizeXs="10"
                            className="ion-text-left m-0 p-0 pl-1"
                            onClick={() => toggleDivisionExpansion(optionGroupIndex)}
                          >
                            <IonLabel className="text-primary font-weight-bold ion-text-wrap">
                              {`Division - ${optionGroup.division.name}`}
                            </IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                      <IonCol sizeSm="1" sizeXs="2" className="ion-text-right p-0">
                        <IonIcon
                          className="text-primary"
                          size="small"
                          icon={
                            optionGroup.division.isExpanded
                              ? chevronDownOutline
                              : chevronUpOutline
                          }
                          onClick={() => toggleDivisionExpansion(optionGroupIndex)} 
                        />
                      </IonCol>
                    </IonRow>

                    {/* If the division is expanded, show the classes that make up the division */}
                    {(optionGroup.division.isExpanded && optionGroup.classes && (optionGroup.classes.length > 0)) && (
                      <>
                        {optionGroup.classes.map((cls, classIndex) => (
                          <IonItemGroup key={classIndex}>
                            <IonRow
                              className={`${optionGroup.division ? "bg-lighter" : "bg-white"} border-top align-items-center pr-1`}
                            >
                              <IonCol sizeSm="1" sizeXs="2" className="pl-2 pt-2 pr-0 mr-0">
                                <IonCheckbox
                                  checked={cls.isChecked}
                                  onIonChange={(e) => {
                                    toggleDivisionClassCheckbox(optionGroupIndex, classIndex, e.detail.checked);
                                  }}
                                  className="px-0"
                                />
                              </IonCol>
                              <IonCol sizeSm="7" sizeXs="5" className="px-0">
                                <IonLabel className="px-0 ion-text-wrap">
                                  {cls.number}. {cls.name}
                                </IonLabel>
                              </IonCol>
                              <IonCol sizeSm="3" sizeXs="3" className="px-0 text-center">
                                <IonText className="px-0 ion-text-wrap">DATE HOLDER</IonText>
                              </IonCol>
                              <IonCol sizeSm="1" sizeXs="2" className="px-0 text-center">
                                <IonText>{cls.entryFee ? "$" + cls.entryFee : ""}</IonText>
                              </IonCol>
                            </IonRow>
                          </IonItemGroup>
                        ))}
                      </>
                    )}
                  </>
                )}

                {/* If there is a single class, render it without any division info */}
                {optionGroup.class && (
                  <IonRow
                      className={`bg-lighter border-top align-items-center pr-1`}
                    >
                      <IonCol sizeSm="1" sizeXs="2" className="pl-2 pt-2 pr-0 mr-0">
                        <IonCheckbox
                          checked={optionGroup.class.isChecked}
                          onIonChange={(e) =>
                            toggleClassCheckbox(optionGroupIndex, e.detail.checked)
                          }
                          className="px-0"
                        />
                      </IonCol>
                      <IonCol sizeSm="7" sizeXs="5" className="px-0">
                        <IonLabel className="px-0 ion-text-wrap">
                          Class: {optionGroup.class.number}. {optionGroup.class.name}
                        </IonLabel>
                      </IonCol>
                      <IonCol sizeSm="3" sizeXs="3" className="px-0 text-center">
                        <IonText className="px-0 ion-text-wrap">DATE HOLDER</IonText>
                      </IonCol>
                      <IonCol sizeSm="1" sizeXs="2" className="px-0 text-center">
                        <IonText>{optionGroup.class.entryFee ? "$" + optionGroup.class.entryFee : ""} </IonText>
                      </IonCol>
                    </IonRow>
                )}
              </IonItemGroup>
            ))}
          </IonList>
          :
          <p>No options found.</p>
        }
      </IonCol>
    </IonRow>
  );
};

export default ClassList;
