import {
    IonButton,
    IonCard,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
    IonSearchbar,
} from "@ionic/react";
import { createGesture, Gesture } from '@ionic/react';
import React, { useContext, useEffect, useState } from "react";
import { Event, EventBreak, EventClass, EventDay, EventRing, ScheduleItem } from "../../../models";
import ErrorAlert from "../../Errors/ErrorAlert";
import { PersonContext } from "../../../context/PersonContext";
import SuccessBanner from "../../Banners/SuccessBanner";
import { getEventClassesByEventId } from "../../../utilities/eventClass/EventClass";
import Spinner from "../../Spinners/Spinner";
import { getEventDaysByEventId } from "../../../utilities/eventDay/EventDay";
import { getEventRingsByEventId } from "../../../utilities/eventRing/EventRing";
import ScheduleDayNavbar from "../../Navbars/ScheduleDayNavbar";
import SelectEventRing from "../../EventRing/SelectEventRing";
import { Table } from "reactstrap";
import { getEventBreaksByEventId } from "../../../utilities/eventBreak/EventBreak";
import { getEventClassTypeAbbreviation } from "../../../utilities/eventClass/EventClassTypes";
import { chevronBackOutline, chevronForwardOutline, createOutline } from "ionicons/icons";
import { CreateScheduleItemInput } from "../../../API";
import { createScheduleItem, deleteScheduleItem, getScheduleItemsByEventId } from "../../../utilities/scheduleItem/ScheduleItem";
import TimePicker from "../../TimePicker";
import { generateSchedulePDF } from "../../../utilities/reports/SchedulePDF";
import moment from "moment";
import DownloadLink from "../../PDF/DownloadLink";

interface _Props {
    currentEvent: Event
}

// Left-hand side
interface scheduleData {
    day?: EventDay
    ring?: EventRing
    order?: number
    time?: string
}

// Left-hand side
interface temporaryClassScheduleItem {
    id: string
    number: string
    name: string
    type: string
    isScheduled: boolean
    data?: scheduleData[] 
    eventClass?: EventClass
    scheduleItem?: ScheduleItem
}

interface temporaryBreakScheduleItem {
    id: string
    name: string
    time: string
    isDisplayOnSchedule: boolean
    isScheduled: boolean
    data?: scheduleData[] 
    eventBreak?: EventBreak
    scheduleItem?: ScheduleItem
}

// Right-hand side
interface tableScheduleItem {
    id: string
    number: string
    name: string
    type: string
    order: number
    topYCoord?: number
    bottomYCoord?: number
    droppedYCoord: number
    time?: string
    day?: EventDay
    ring?: EventRing
    eventClass?: EventClass
    eventBreak?: EventBreak
    scheduleItem?: ScheduleItem
}

const EventScheduler: React.FC<_Props> = ({currentEvent}) => {
    const user = useContext(PersonContext);

    const initialContainer = document.querySelector("#drag-container");

    const [showItemsToAdd, setShowItemsToAdd] = useState(true);
    const [isEditTimes, setIsEditTimes] = useState(false);
    const [showClasses, setShowClasses] = useState(true); //If not classes, then breaks
    const [gestures, setGestures] = useState<Gesture[] | null | undefined>();
    const [days, setDays] = useState<EventDay[] | null | undefined>();
    const [rings, setRings] = useState<EventRing[] | null | undefined>();
    const [selectedDay, setSelectedDay] = useState<EventDay | null | undefined>();
    const [selectedRing, setSelectedRing] = useState<EventRing | null | undefined>();
    const [eventClasses, setEventClasses] = useState<EventClass[] | null | undefined>();
    const [searchText, setSearchText] = useState("");
    const [temporaryClassScheduleItems, setTemporaryClassScheduleItems] = useState<temporaryClassScheduleItem[]>([]);
    const [filteredClassTemporaryScheduleItems, setFilteredClassTemporaryScheduleItems] = useState<temporaryClassScheduleItem[]>([]);
    const [temporaryBreakScheduleItems, setTemporaryBreakScheduleItems] = useState<temporaryBreakScheduleItem[]>([]);
    const [filteredBreakTemporaryScheduleItems, setFilteredBreakTemporaryScheduleItems] = useState<temporaryBreakScheduleItem[]>([]);
        
    const [tableTemporaryScheduleItems, setTableTemporaryScheduleItems] = useState<tableScheduleItem[]>([]);
    const [scheduleItems, setScheduleItems] = useState<ScheduleItem[] | null | undefined>();
    const [filteredScheduleItems, setFilteredScheduleItems] = useState<ScheduleItem[] | null | undefined>();
    const [currentYCoord, setCurrentYCoord] = useState(0);
    const [currentAboveItem, setCurrentAboveItem] = useState<number | undefined>();
    const [currentDroppedItem, setCurrentDroppedItem] = useState<{node: ChildNode, x: number, y: number} | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    const formatEventClasses = (eventClasses: EventClass[], currentScheduleItems?: ScheduleItem[]) => {
        let formattedArray: temporaryClassScheduleItem[] = [];
        const allScheduleItems: ScheduleItem[] | null | undefined = currentScheduleItems || scheduleItems;
        for (let i = 0; i < eventClasses.length; i++) {
            const current = eventClasses[i];
            let scheduleData: scheduleData[] = [];
            if (allScheduleItems) {
                const index = allScheduleItems.findIndex((si: ScheduleItem) => (si.eventBreak && si.eventBreak.id === current.id));
                if (index > -1) {
                    const foundScheduleItem: ScheduleItem = allScheduleItems[index];
                    let dataItem: scheduleData = {
                        day: foundScheduleItem.eventDay || undefined,
                        ring: foundScheduleItem.eventRing || undefined
                    };
                    scheduleData.push(dataItem);
                }
            }
            let formattedItem: temporaryClassScheduleItem = {
                id: current.id,
                name: current.name,
                number: current.number ? current.number.toString() : "",
                type: current.type || "",
                eventClass: current,
                isScheduled: !!(scheduleData && scheduleData.length > 0),
                data: scheduleData
            };
            formattedArray.push(formattedItem);
        }

        setTemporaryClassScheduleItems(formattedArray);
        setFilteredClassTemporaryScheduleItems(formattedArray);

        return formattedArray;
    }

    const getEventClasses = async (eventId: string) => {
        let eventClasses: EventClass[] = [];
        const queryResult = await getEventClassesByEventId(eventId);
        if (queryResult.isSuccess) {
            eventClasses = queryResult.result;
            setEventClasses(eventClasses);
            formatEventClasses(eventClasses);
        }
        return eventClasses;
    }

    const getEventBreaks = async (eventId: string) => {
        let eventBreaks: EventBreak[] = [];
        const queryResult = await getEventBreaksByEventId(eventId);
        if (queryResult.isSuccess) {
            eventBreaks = queryResult.result;
            formatEventBreaks(eventBreaks);
        }
        return eventBreaks;
    }

    const formatEventBreaks = (eventBreaks: EventBreak[]) => {
        let formattedArray: temporaryBreakScheduleItem[] = [];

        for (let i = 0; i < eventBreaks.length; i++) {
            const current = eventBreaks[i];
            let scheduleData: scheduleData[] = [];
            if (scheduleItems) {
                const index = scheduleItems.findIndex((si: ScheduleItem) => (si.eventBreak && si.eventBreak.id === current.id));
                if (index > -1) {
                    const foundScheduleItem: ScheduleItem = scheduleItems[index];
                    let dataItem: scheduleData = {
                        day: foundScheduleItem.eventDay || undefined,
                        ring: foundScheduleItem.eventRing || undefined
                    };
                    scheduleData.push(dataItem);
                }
            }
            let formattedItem: temporaryBreakScheduleItem = {
                id: current.id,
                name: current.name || "",
                time: current.time || "",
                isDisplayOnSchedule: current.isDisplayedOnSchedule || false,
                eventBreak: current,
                isScheduled: !!(scheduleData && scheduleData.length > 0),
                data: scheduleData
            };
            formattedArray.push(formattedItem);
        }
        const sorted = formattedArray.sort((a, b) => a.name.localeCompare(b.name));
        setTemporaryBreakScheduleItems(sorted || formattedArray);
        setFilteredBreakTemporaryScheduleItems(sorted || formattedArray);
        return sorted || formattedArray;
    }

    const formatScheduleItems = (scheduleItems: ScheduleItem[], currentDay?: EventDay, currentRing?: EventRing, currentClassScheduleItems?: temporaryClassScheduleItem[], currentBreakScheduleItems?: temporaryBreakScheduleItem[]) => {
        const day = currentDay || selectedDay;
        const ring = currentRing || selectedRing;

        console.log("Day: ", day);
        console.log("Ring: ", ring);

        let classItems: temporaryClassScheduleItem[] = currentClassScheduleItems || temporaryClassScheduleItems;
        let breakItems: temporaryBreakScheduleItem[] = currentBreakScheduleItems || temporaryBreakScheduleItems;

        let tableItems: tableScheduleItem[] = [];
        let filteredItems: ScheduleItem[] = [];
        if (scheduleItems) {
            console.log("Schedule items: ", scheduleItems);
            for (var i = 0; i < scheduleItems.length; i++) {
                const currentItem = scheduleItems[i];

                console.log("format current item: ", currentItem.eventRing?.name);

                // Only show the items that match the selected day and ring
                if (day && ring && currentItem.eventDayId === day?.id && currentItem.eventRingId === ring?.id) {
                    const formattedItem: tableScheduleItem = {
                        id: currentItem.id,
                        number: currentItem.eventClass?.number ? currentItem.eventClass?.number?.toString() : "",
                        name: currentItem.eventClass ? currentItem.eventClass.name : (currentItem.eventBreak ? (currentItem.eventBreak.name || "") : ""),
                        type: currentItem.eventClass ? (currentItem.eventClass.type || "") : "break",
                        order: currentItem.displayOrder || 999,
                        topYCoord: 0,
                        bottomYCoord: 0,
                        droppedYCoord: 0,
                        time: currentItem.startTime || "",
                        day: currentItem.eventDay || undefined,
                        ring: currentItem.eventRing || undefined,
                        eventClass: currentItem.eventClass || undefined,
                        eventBreak: currentItem.eventBreak || undefined,
                        scheduleItem: currentItem
                    };
                    console.log("push: ", formattedItem);
                    tableItems.push(formattedItem);
                    filteredItems.push(currentItem);

                    const currentScheduleData: scheduleData = {
                        day: currentItem.eventDay || undefined,
                        ring: currentItem.eventRing || undefined,
                    };
                    if (classItems) {
                        const regularItemIndex = classItems?.findIndex(tsi => tsi.id === currentItem.eventClass?.id);
                        if (regularItemIndex > -1) {
                            let regularItem = classItems[regularItemIndex];
                            const currentItemScheduleData: scheduleData[] | undefined = regularItem.data;
                            let updatedScheduleData: scheduleData[] | undefined = currentItemScheduleData ? currentItemScheduleData.concat([currentScheduleData]) : [currentScheduleData];
                            const updatedItem: temporaryClassScheduleItem = {
                                ...regularItem,
                                isScheduled: true,
                                data: updatedScheduleData
                            };
                            setTemporaryClassScheduleItems(prev => [
                                ...prev.slice(0, regularItemIndex),
                                updatedItem,
                                ...prev.slice(regularItemIndex + 1),
                            ]);   
                            setFilteredClassTemporaryScheduleItems(prev => [
                                ...prev.slice(0, regularItemIndex),
                                updatedItem,
                                ...prev.slice(regularItemIndex + 1),
                            ]);
                        }             
                    }
                    if (breakItems) {
                        const regularItemIndex = breakItems?.findIndex(tsi => tsi.id === currentItem.eventBreak?.id);
                        if (regularItemIndex > -1) {
                            let regularItem = breakItems[regularItemIndex];
                            const currentItemScheduleData: scheduleData[] | undefined = regularItem.data;
                            let updatedScheduleData: scheduleData[] | undefined = currentItemScheduleData ? currentItemScheduleData.concat([currentScheduleData]) : [currentScheduleData];
                            const updatedItem: temporaryBreakScheduleItem = {
                                ...regularItem,
                                isScheduled: true,
                                data: updatedScheduleData
                            };
                            setTemporaryBreakScheduleItems(prev => [
                                ...prev.slice(0, regularItemIndex),
                                updatedItem,
                                ...prev.slice(regularItemIndex + 1),
                            ]);   
                            setFilteredBreakTemporaryScheduleItems(prev => [
                                ...prev.slice(0, regularItemIndex),
                                updatedItem,
                                ...prev.slice(regularItemIndex + 1),
                            ]);
                        }             
                    }
                }
            }
        }
        console.log("final table items: ", tableItems);
        setTableTemporaryScheduleItems(() => tableItems);
        setFilteredScheduleItems(() => filteredItems);
        // handleUpdateYCoordinates(tableItems);
        setIsLoading(false);
    }

    async function getScheduleItemsByEvent() {
        setIsLoading(true);
        const queryResult = await getScheduleItemsByEventId(currentEvent.id);
        if (queryResult.isSuccess) {
            const items: ScheduleItem[] = queryResult.result;
            const sortedItems = items.sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0));
            setScheduleItems(sortedItems || items);
            return queryResult.result;
        } else {
            setScheduleItems(undefined);
            setIsLoading(false);
        }
    }

    const setUp = async (event: Event) => {
        let currentEventClasses: EventClass[] = await getEventClasses(currentEvent.id);
        let currentEventBreaks: EventBreak[] = await getEventBreaks(currentEvent.id);

        const formattedClasses = formatEventClasses(currentEventClasses);
        const formattedBreaks = formatEventBreaks(currentEventBreaks);

        let daysList: EventDay[] = [];
        let ringsList: EventRing[] = [];

        let defaultDay;
        let defaultRing;
        
        const queryDaysResult = await getEventDaysByEventId(event.id);
        if (queryDaysResult.isSuccess) {
            daysList = queryDaysResult.result;
            setDays(daysList);
            defaultDay = daysList[0];
            setSelectedDay(defaultDay);
        }

        const queryRingsResult = await getEventRingsByEventId(event.id);
        if (queryRingsResult.isSuccess) {
            ringsList = queryRingsResult.result;
            setRings(ringsList);
            if (ringsList && ringsList.length > 0) {
                defaultRing = ringsList[0];
                setSelectedRing(defaultRing);
            } else {
                setError("Switch to the Rings tab to add a ring to your event.");
            }
        }

        if (defaultDay && defaultRing) {
            const scheduleItems: ScheduleItem[] | null = await getScheduleItemsByEvent();
            if (scheduleItems) {
                await formatScheduleItems(scheduleItems, defaultDay, defaultRing, formattedClasses, formattedBreaks);
            }
        }
    }

    useEffect(() => {
        setUp(currentEvent);
    }, [currentEvent]);

    useEffect(() => {
        updateGestures(true);
    }, [initialContainer]);

    useEffect(() => {
        if (tableTemporaryScheduleItems && tableTemporaryScheduleItems.length > 0) {
            const lastElement = tableTemporaryScheduleItems[tableTemporaryScheduleItems.length-1];
            if (!lastElement.bottomYCoord || lastElement.bottomYCoord === 1) handleUpdateYCoordinates(tableTemporaryScheduleItems);
        }
        
    }, [tableTemporaryScheduleItems]);

    useEffect(() => {
        if (currentDroppedItem?.node && currentDroppedItem.x && currentDroppedItem.y) {
            handleDrop(currentDroppedItem.node, currentDroppedItem.x, currentDroppedItem.y, tableTemporaryScheduleItems);
        }
        
    }, [currentDroppedItem]);

    /**
     * Shows where the moving item will be placed in the right-hand table
     */
    useEffect(() => {
        if (currentYCoord !== 0 && tableTemporaryScheduleItems && tableTemporaryScheduleItems.length > 0) {
            handleUpdateYCoordinates(tableTemporaryScheduleItems);
            getItemOrder(currentYCoord, tableTemporaryScheduleItems);
        }
    }, [currentYCoord]);

    const updateGestures = (isSetToClasses: boolean) => {
        if (gestures && gestures.length > 0) {
            // First, destroy the ones there
            gestures.forEach(gesture => {
                gesture.destroy();
            });
        }

        let arr: any = [];

        if (isSetToClasses) {
            let listElement = document.querySelector('#drag-container.drag-container-classes');
            arr = listElement?.childNodes;
        } else {
            let listElement = document.querySelector('#drag-container.drag-container-breaks');
            arr = listElement?.childNodes;
        }
        
        if (arr && arr.length) {
            let gestureArray = [];
            for (let i = 0; i < arr.length; i++) {
                const currentElement = arr[i];
                const dragGesture = createGesture({
                    el: currentElement,
                    gestureName: "drag",
                    threshold: 1,
                    disableScroll: true,
                    onStart: (detail) => { 
                        const element = currentElement as HTMLElement;
                        element.style.transition = "";
                        element.style.opacity = "0.8";
                        element.style.fontWeight = "bold";
                    },
                    onMove: (detail) => { 
                        const element = currentElement as HTMLElement;

                        const sideMenuElement = document.querySelector('#side-menu');
                        const sideMenuHTMLElement = sideMenuElement as HTMLElement;
                        const sideMenuWidth = sideMenuHTMLElement.offsetWidth || 0;

                        element.style.position = "fixed";
                        element.style.left = `${detail.startX - sideMenuWidth - 20}px`;
                        element.style.top = `${detail.startY - 20}px`;                        
                        element.style.transform = `translate(${detail.deltaX}px, ${detail.deltaY}px)`;
                        element.style.zIndex = "11";

                        const isInDropZone = checkDropZoneHover(detail.currentX, detail.currentY);
                        if (isInDropZone) {
                            setCurrentYCoord(detail.currentY);
                        }
                    },
                    onEnd: (detail) => { 
                        setCurrentDroppedItem({node: currentElement, x: detail.currentX, y: detail.currentY});
                    },
                });
                dragGesture.enable();
                gestureArray.push(dragGesture);
            }
            setGestures(gestureArray);
        }
    }

    const handleSelectEventRing = (eventRing: EventRing) => {
        setIsLoading(true);
        setSelectedRing(eventRing);
        if (scheduleItems) formatScheduleItems(scheduleItems, (selectedDay || undefined), eventRing);
        setIsLoading(false);
    }

    const handleSelectEventDay = (index: number) => {
        setIsLoading(true);
        if (days) {
            const day = days[index];
            setSelectedDay(day);
            if (scheduleItems) formatScheduleItems(scheduleItems, day);
        }
        setIsLoading(false);
    }

    const handleTimeChange = (newTime: string, item: tableScheduleItem, index: number) => {
        const updatedTableItem: tableScheduleItem = {
            ...item,
            time: newTime
        };
        if (tableTemporaryScheduleItems && tableTemporaryScheduleItems.length > 0) {
            const updatedTableItems: tableScheduleItem[] = [
                ...tableTemporaryScheduleItems.slice(0, index),
                updatedTableItem,
                ...tableTemporaryScheduleItems.slice(index + 1)
            ];
            setTableTemporaryScheduleItems(updatedTableItems);
        }
    }

    const handleSwitchView = async (isViewClasses: boolean) => {
        await setShowClasses(isViewClasses);
        updateGestures(isViewClasses);
    }

    const handleSearchInput = (input: string) => {
        if (showClasses) {
            // If search input is empty, reset to all accepted entries
            if (!input || input === "") {
                setFilteredClassTemporaryScheduleItems(temporaryClassScheduleItems);
            } else {
                let result = temporaryClassScheduleItems?.filter(tsi => (tsi.name.includes(input) || tsi.number.includes(input)));
                if (result) {
                    setFilteredClassTemporaryScheduleItems(result);
                }
            }
        } else {
            if (!input || input === "") {
                setFilteredBreakTemporaryScheduleItems(temporaryBreakScheduleItems);
            } else {
                let result = temporaryBreakScheduleItems?.filter(tsi => (tsi.name.includes(input)));
                if (result) {
                    setFilteredBreakTemporaryScheduleItems(result);
                }
            }
        }
    }

    const getItemOrder = (y: number, tableItems: tableScheduleItem[]) => {
        let itemIndex = 0;
        if (tableItems && tableItems.length > 0) {
            // Figure out where this item is in relation to the current table items
            for (let i = 0; i < tableItems.length; i++) {
                const element = tableItems[i];
                if (itemIndex === 0) {
                    if (element.bottomYCoord && element.topYCoord) {
                        if (y < element.topYCoord) {
                            // Current item is still above this one, keep looking in the list
                        } else if (i === tableItems.length-1) {
                            // Current item is below the top of the last item, make it the new last item
                            setCurrentAboveItem(i);
                            return i+1;
                        } else if (y > element.bottomYCoord) {
                            // Keep moving through the loop
                        } else {
                            setCurrentAboveItem(i);
                            return i+1;
                        }
                    } else {
                        setError("System error: cannot place items in order.");
                    }
                } else {
                    // Update the other items to have lower values?
                }
            }
        } 
        return itemIndex;
    }

    const checkDropZoneHover = (x: number, y: number) => {
        const dropZone = document.querySelector('#drop-container')?.getBoundingClientRect();
        if (dropZone) {
            const inZone = isInZone(x, y, dropZone);
            return inZone;
        } else {
            setError("No drop zone found");
            return false;
        }
    }

    const isInZone = (x: number, y: number, zone: DOMRect) => {
        if (x < zone.left || x >= zone.right) {
            return false;
        } 
        if (y < zone.top || y >= zone.bottom) {
            return false;
        } 
        return true;
    }

    const handleDrop = async (item: ChildNode, x: number, y: number, tableItems?: tableScheduleItem[]) => {
        const element = item as HTMLElement;
        const itemIsInZone = checkDropZoneHover(x, y);
        if (itemIsInZone) {
            const currentScheduleData: scheduleData = {
                day: selectedDay || undefined,
                ring: selectedRing || undefined,
            };

            // Check if this is a class or a break item
            if (showClasses) {
                // Update the filtered array
                if (filteredClassTemporaryScheduleItems) {
                    const filteredItemIndex = filteredClassTemporaryScheduleItems?.findIndex(ftsi => ftsi.id === element.id);
                    if (filteredItemIndex > -1) {
                        const filteredItem = filteredClassTemporaryScheduleItems[filteredItemIndex];
                        const currentItemScheduleData: scheduleData[] | undefined = filteredItem.data;
                        let updatedScheduleData: scheduleData[] | undefined = currentItemScheduleData ? currentItemScheduleData.concat([currentScheduleData]) : [currentScheduleData];
                        const updatedItem: temporaryClassScheduleItem = {
                            ...filteredItem,
                            isScheduled: true,
                            data: updatedScheduleData
                        };
                        setFilteredClassTemporaryScheduleItems(prev => [
                            ...prev.slice(0, filteredItemIndex),
                            updatedItem,
                            ...prev.slice(filteredItemIndex + 1)
                        ]);    
                    }
                                
                }

                // Update the regular array
                let regularItem: temporaryClassScheduleItem | undefined = undefined;
                if (temporaryClassScheduleItems) {
                    const regularItemIndex = temporaryClassScheduleItems?.findIndex(tsi => tsi.id === element.id);
                    if (regularItemIndex > -1) {
                        regularItem = temporaryClassScheduleItems[regularItemIndex];
                        const currentItemScheduleData: scheduleData[] | undefined = regularItem.data;
                        let updatedScheduleData: scheduleData[] | undefined = currentItemScheduleData ? currentItemScheduleData.concat([currentScheduleData]) : [currentScheduleData];
                        const updatedItem: temporaryClassScheduleItem = {
                            ...regularItem,
                            isScheduled: true,
                            data: updatedScheduleData
                        };
                        setTemporaryClassScheduleItems(prev => [
                            ...prev.slice(0, regularItemIndex),
                            updatedItem,
                            ...prev.slice(regularItemIndex + 1),
                        ]);   
                    }             
                }

                if (regularItem) {
                    // Add a new tableScheduleItem
                    let previousTableItems: tableScheduleItem[] = tableItems ? [...tableItems] : [];
                    const itemIndex = getItemOrder(y, previousTableItems);
                    let newItem: tableScheduleItem = {
                        id: regularItem.id,
                        order: itemIndex || 999,
                        topYCoord: 1,
                        bottomYCoord: 1,
                        droppedYCoord: y,
                        name: regularItem.name,
                        number: regularItem.number,
                        type: regularItem.type,
                        eventClass: regularItem.eventClass
                    };
                    setTableTemporaryScheduleItems(prev => ([
                        ...prev.slice(0, itemIndex),
                        newItem,
                        ...prev.slice(itemIndex)
                    ].sort((a: tableScheduleItem, b: tableScheduleItem) => a.order - b.order)));                  
                }
            } else {
                // Must be a break item
                // Update the filtered array
                if (filteredBreakTemporaryScheduleItems) {
                    const filteredItemIndex = filteredBreakTemporaryScheduleItems?.findIndex(ftsi => ftsi.id === element.id);
                    if (filteredItemIndex > -1) {
                        const filteredItem = filteredBreakTemporaryScheduleItems[filteredItemIndex];
                        const currentItemScheduleData: scheduleData[] | undefined = filteredItem.data;
                        let updatedScheduleData: scheduleData[] | undefined = currentItemScheduleData ? currentItemScheduleData.concat([currentScheduleData]) : [currentScheduleData];
                        const updatedItem: temporaryBreakScheduleItem = {
                            ...filteredItem,
                            isScheduled: true,
                            data: updatedScheduleData
                        };
                        setFilteredBreakTemporaryScheduleItems(prev => [
                            ...prev.slice(0, filteredItemIndex),
                            updatedItem,
                            ...prev.slice(filteredItemIndex + 1)
                        ]);    
                    }
                                
                }

                // Update the regular array
                let regularItem: temporaryBreakScheduleItem | undefined = undefined;
                if (temporaryBreakScheduleItems) {
                    const regularItemIndex = temporaryBreakScheduleItems?.findIndex(tsi => tsi.id === element.id);
                    if (regularItemIndex > -1) {
                        regularItem = temporaryBreakScheduleItems[regularItemIndex];
                        const currentItemScheduleData: scheduleData[] | undefined = regularItem.data;
                        let updatedScheduleData: scheduleData[] | undefined = currentItemScheduleData ? currentItemScheduleData.concat([currentScheduleData]) : [currentScheduleData];
                        const updatedItem: temporaryBreakScheduleItem = {
                            ...regularItem,
                            isScheduled: true,
                            data: updatedScheduleData
                        };
                        setTemporaryBreakScheduleItems(prev => [
                            ...prev.slice(0, regularItemIndex),
                            updatedItem,
                            ...prev.slice(regularItemIndex + 1),
                        ]);   
                    }             
                }

                if (regularItem) {
                    // Add a new tableScheduleItem
                    let previousTableItems: tableScheduleItem[] = tableItems ? [...tableItems] : [];
                    const itemIndex = getItemOrder(y, previousTableItems);
                    let newItem: tableScheduleItem = {
                        id: regularItem.id,
                        order: itemIndex || 999,
                        topYCoord: 1,
                        bottomYCoord: 1,
                        droppedYCoord: y,
                        name: regularItem.name,
                        number: "",
                        type: "break",
                        eventBreak: regularItem.eventBreak
                    };
                    setTableTemporaryScheduleItems(prev => ([
                        ...prev.slice(0, itemIndex),
                        newItem,
                        ...prev.slice(itemIndex)
                    ].sort((a: tableScheduleItem, b: tableScheduleItem) => a.order - b.order)));                  
                }
            }
            
        }

        element.style.transition = `.2s ease-out`;
        element.style.transform = `translate(0,0)`;
        element.style.zIndex = "inherit";
        element.style.opacity = "1";
        element.style.fontWeight = "normal";
        element.style.position = "inherit"
        setCurrentAboveItem(undefined);
    }

    const handleUpdateYCoordinates = async (tableItems: tableScheduleItem[]) => {
        let updatedItems: tableScheduleItem[] = [];
        if (tableItems && tableItems.length > 0) {
            const sorted: tableScheduleItem[] = tableItems.sort((a: tableScheduleItem, b: tableScheduleItem): number => {
                const droppedYResult = a.droppedYCoord - b.droppedYCoord;
                const orderResult = a.order - b.order;
                return orderResult || droppedYResult;
            });
            for (let i = 0; i < sorted.length; i++) {
                const item = sorted[i];
                const tableElement = document.querySelector(`#table-${item.id}`);
                if (tableElement) {
                    const rect = tableElement.getBoundingClientRect();
                    const updatedItem: tableScheduleItem = {
                        ...item,
                        order: i,
                        topYCoord: rect.top,
                        bottomYCoord: rect.bottom,
                        droppedYCoord: 0
                    };
                    updatedItems.push(updatedItem);
                }
            }
            await setTableTemporaryScheduleItems(updatedItems);
        }
    }

    const handleRemoveTableItem = (item: tableScheduleItem, index: number) => {
        // Check if this is a class or a break
        if (showClasses) {
            // Update the filtered array
            if (filteredClassTemporaryScheduleItems) {
                const filteredItemIndex = filteredClassTemporaryScheduleItems?.findIndex(ftsi => ftsi.id === item.id);
                if (filteredItemIndex > -1) {
                    const filteredItem = filteredClassTemporaryScheduleItems[filteredItemIndex];
                    let updatedItemScheduleData: scheduleData[] | undefined = filteredItem.data;
                    if (updatedItemScheduleData && updatedItemScheduleData.length > 0) {
                        let scheduleDataIndex = updatedItemScheduleData.findIndex(item => item.day?.id === selectedDay?.id && item.ring?.id === selectedRing?.id);
                        if (scheduleDataIndex > -1) {
                            updatedItemScheduleData = [
                                ...updatedItemScheduleData.slice(0, scheduleDataIndex),
                                ...updatedItemScheduleData.slice(scheduleDataIndex + 1)
                            ];
                        }
                    }
                    const updatedItem: temporaryClassScheduleItem = {
                        ...filteredItem,
                        isScheduled: !!(updatedItemScheduleData && updatedItemScheduleData.length > 0),
                        data: updatedItemScheduleData
                    };
                    setFilteredClassTemporaryScheduleItems(prev => [
                        ...prev.slice(0, filteredItemIndex),
                        updatedItem,
                        ...prev.slice(filteredItemIndex + 1)
                    ]);    
                }
                            
            }

            // Update the regular array
            let regularItem: temporaryClassScheduleItem | undefined = undefined;
            if (temporaryClassScheduleItems) {
                const regularItemIndex = temporaryClassScheduleItems?.findIndex(tsi => tsi.id === item.id);
                if (regularItemIndex > -1) {
                    regularItem = temporaryClassScheduleItems[regularItemIndex];
                    let updatedItemScheduleData: scheduleData[] | undefined = regularItem.data;
                    if (updatedItemScheduleData && updatedItemScheduleData.length > 0) {
                        let scheduleDataIndex = updatedItemScheduleData.findIndex(item => item.day?.id === selectedDay?.id && item.ring?.id === selectedRing?.id);
                        if (scheduleDataIndex > -1) {
                            updatedItemScheduleData = [
                                ...updatedItemScheduleData.slice(0, scheduleDataIndex),
                                ...updatedItemScheduleData.slice(scheduleDataIndex + 1)
                            ];
                        }
                    }
                    
                    const updatedItem: temporaryClassScheduleItem = {
                        ...regularItem,
                        isScheduled: !!(updatedItemScheduleData && updatedItemScheduleData.length > 0),
                        data: updatedItemScheduleData
                    };
                    setTemporaryClassScheduleItems(prev => [
                        ...prev.slice(0, regularItemIndex),
                        updatedItem,
                        ...prev.slice(regularItemIndex + 1),
                    ]);   
                }             
            }
        } else {
            // Update the filtered array
            if (filteredBreakTemporaryScheduleItems) {
                const filteredItemIndex = filteredBreakTemporaryScheduleItems?.findIndex(ftsi => ftsi.id === item.id);
                if (filteredItemIndex > -1) {
                    const filteredItem = filteredBreakTemporaryScheduleItems[filteredItemIndex];
                    let updatedItemScheduleData: scheduleData[] | undefined = filteredItem.data;
                    if (updatedItemScheduleData && updatedItemScheduleData.length > 0) {
                        let scheduleDataIndex = updatedItemScheduleData.findIndex(item => item.day?.id === selectedDay?.id && item.ring?.id === selectedRing?.id);
                        if (scheduleDataIndex > -1) {
                            updatedItemScheduleData = [
                                ...updatedItemScheduleData.slice(0, scheduleDataIndex),
                                ...updatedItemScheduleData.slice(scheduleDataIndex + 1)
                            ];
                        }
                    }
                    const updatedItem: temporaryBreakScheduleItem = {
                        ...filteredItem,
                        isScheduled: !!(updatedItemScheduleData && updatedItemScheduleData.length > 0),
                        data: updatedItemScheduleData
                    };
                    setFilteredBreakTemporaryScheduleItems(prev => [
                        ...prev.slice(0, filteredItemIndex),
                        updatedItem,
                        ...prev.slice(filteredItemIndex + 1)
                    ]);    
                }
                            
            }

            // Update the regular array
            let regularItem: temporaryBreakScheduleItem | undefined = undefined;
            if (temporaryBreakScheduleItems) {
                const regularItemIndex = temporaryBreakScheduleItems?.findIndex(tsi => tsi.id === item.id);
                if (regularItemIndex > -1) {
                    regularItem = temporaryBreakScheduleItems[regularItemIndex];
                    let updatedItemScheduleData: scheduleData[] | undefined = regularItem.data;
                    if (updatedItemScheduleData && updatedItemScheduleData.length > 0) {
                        let scheduleDataIndex = updatedItemScheduleData.findIndex(item => item.day?.id === selectedDay?.id && item.ring?.id === selectedRing?.id);
                        if (scheduleDataIndex > -1) {
                            updatedItemScheduleData = [
                                ...updatedItemScheduleData.slice(0, scheduleDataIndex),
                                ...updatedItemScheduleData.slice(scheduleDataIndex + 1)
                            ];
                        }
                    }
                    const updatedItem: temporaryBreakScheduleItem = {
                        ...regularItem,
                        isScheduled: !!(updatedItemScheduleData && updatedItemScheduleData.length > 0),
                        data: updatedItemScheduleData
                    };
                    setTemporaryBreakScheduleItems(prev => [
                        ...prev.slice(0, regularItemIndex),
                        updatedItem,
                        ...prev.slice(regularItemIndex + 1),
                    ]);   
                }             
            }
        }

        if (tableTemporaryScheduleItems) {
            let updatedTableItems: tableScheduleItem[] = [
                ...tableTemporaryScheduleItems.slice(0, index),
                ...tableTemporaryScheduleItems.slice(index + 1)
            ];
            setTableTemporaryScheduleItems(updatedTableItems);
        }   
    }

    const handleSaveScheduleItems = async () => {
        setError("");
        setSuccess("");
        setIsLoading(true);

        let newScheduleItems: ScheduleItem[] | null | undefined = scheduleItems;

        if (filteredScheduleItems && filteredScheduleItems.length > 0) {
            for (let i = 0; i < filteredScheduleItems.length; i++) {
                const element = filteredScheduleItems[i];
                const deleteResult = await deleteScheduleItem({id: element.id});
                if (deleteResult.isSuccess) {
                    if (newScheduleItems) {
                        const index = newScheduleItems.findIndex(si => si.id === element.id);
                        if (index > -1) {
                            newScheduleItems = [
                                ...newScheduleItems.slice(0, index),
                                ...newScheduleItems.slice(index + 1)
                            ]; 
                        }
                    }
                }
            }
        }
        if (selectedDay && selectedRing) {
            if (tableTemporaryScheduleItems) {
                
                let newFilteredScheduleItems: ScheduleItem[] = [];
                for (let i = 0; i < tableTemporaryScheduleItems.length; i++) {
                    const current = tableTemporaryScheduleItems[i];
                    const createInput: CreateScheduleItemInput = {
                        eventId: currentEvent.id,
                        eventRingId: selectedRing.id,
                        eventDayId: selectedDay.id,
                        // scheduleItemEventRingId: selectedRing.id,
                        // scheduleItemEventDayId: selectedDay.id,
                        displayOrder: i+1,
                        classId: current.eventClass ? current.eventClass.id : "",
                        // scheduleItemEventClassId: current.eventClass ? current.eventClass.id : "",
                        breakId: current.eventBreak ? current.eventBreak.id : "",
                        // scheduleItemEventBreakId: current.eventBreak ? current.eventBreak.id : "",
                        startTime: current.time
                    };
                    const createResult = await createScheduleItem(createInput);
                    if (createResult.isSuccess) {
                        const newScheduleItem: ScheduleItem = createResult.result;
                        newScheduleItems?.push(newScheduleItem)
                        newFilteredScheduleItems.push(newScheduleItem);
                    }
                }
                setScheduleItems(newScheduleItems);
                setFilteredScheduleItems(newFilteredScheduleItems);
            }
        
        } else {
            setError("First, select a day and a ring.");
        }
        setIsLoading(false);
        setIsEditTimes(false);
    }

    const handleDownloadSchedule = async () => {
        setIsDownloading(true);
        if (filteredScheduleItems) {
            await generateSchedulePDF(filteredScheduleItems, currentEvent.name, (selectedDay && selectedDay.awsdate) ? moment(selectedDay.awsdate).format("dddd MMM D, YYYY") : "No Day", selectedRing ? selectedRing.name : "No Ring")
            setIsDownloading(false);
        } else {
            setIsDownloading(false);
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            <IonRow>
                <IonCol size="12">
                    <IonCard mode="md" id="left" className="ion-padding bg-white stretch card-large-height">
                        <IonRow>
                            {showItemsToAdd && (
                                <IonCol size="6">
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                            <IonButton color={showClasses ? "tertiary" : "light"} onClick={() => {handleSwitchView(true)}}>View Classes</IonButton>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                            <IonButton color={showClasses ? "light" : "tertiary"} onClick={() => {handleSwitchView(false)}}>View Breaks</IonButton>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonSearchbar
                                                color="white"  
                                                placeholder="Search" 
                                                value={searchText} 
                                                onIonChange={e => {
                                                    setSearchText(e.detail.value!)
                                                    handleSearchInput(e.detail.value!)
                                                }}
                                            />
                                        </IonCol>
                                        <IonCol size="12">
                                            <p className="description">Scroll to view all classes or breaks.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="scrollable-wrapper border-right">
                                        <IonCol className="scrollable">
                                           {showClasses ?
                                                <>
                                                     {(filteredClassTemporaryScheduleItems && filteredClassTemporaryScheduleItems.length > 0) ?
                                                        <div className="drag-container-classes" id="drag-container">
                                                            {filteredClassTemporaryScheduleItems.map((item, index) => (
                                                                <IonItem key={index} id={item.id} color={item.isScheduled ? "light" : ""} button={true} onClick={() => console.log("Clicked item!")}>
                                                                    <IonLabel>
                                                                        <IonRow>
                                                                            <IonCol>
                                                                                <p className="ion-text-wrap ion-text-left description">{item.number}: {item.name}</p>
                                                                                {item.type !== "" ? <p className="ion-text-wrap ion-text-left description">Type: {getEventClassTypeAbbreviation(item.type)}</p> : <></>}
                                                                            </IonCol>
                                                                        </IonRow>
                                                                        {item.isScheduled && (
                                                                            <IonRow>
                                                                                <IonCol>
                                                                                    <p className="ion-text-wrap ion-text-left description">Scheduled</p>
                                                                                    {(item.data && item.data.length > 0) && (
                                                                                        <>
                                                                                            {item.data.map((scheduleData, index) => (
                                                                                                <p key={index}>{scheduleData.day?.dayofweek} - {scheduleData.ring?.name}</p>
                                                                                            ))}
                                                                                        </>
                                                                                    )}
                                                                                </IonCol>
                                                                            </IonRow>
                                                                        )}
                                                                    </IonLabel>
                                                                </IonItem>
                                                            ))}
                                                        </div>
                                                        :
                                                        <Spinner />
                                                    }
                                                </>
                                                :
                                                <>
                                                    {(filteredBreakTemporaryScheduleItems && filteredBreakTemporaryScheduleItems.length > 0) ?
                                                        <div className="drag-container-breaks" id="drag-container">
                                                            {filteredBreakTemporaryScheduleItems.map((item, index) => (
                                                                <IonItem key={index} id={item.id} color={item.isScheduled ? "light" : ""} button={true}>
                                                                    <IonLabel>
                                                                        <IonRow>
                                                                            <IonCol>
                                                                                <p className="ion-text-wrap ion-text-left description">{item.name}</p>
                                                                                <p className="ion-text-wrap ion-text-left description">{item.time ? item.time + " minutes" : "No time estimate"}</p>
                                                                                <p className="ion-text-wrap ion-text-left description">Display on exhibitors' schedule: <span className={item.isDisplayOnSchedule ? "text-success": "text-danger"}>{item.isDisplayOnSchedule ? "yes": "no"}</span></p>
                                                                            </IonCol>
                                                                        </IonRow>
                                                                        {item.isScheduled && (
                                                                            <IonRow>
                                                                                <IonCol>
                                                                                    <p className="ion-text-wrap ion-text-left description">Scheduled</p>
                                                                                    {(item.data && item.data.length > 0) && (
                                                                                        <>
                                                                                            {item.data.map((scheduleData, index) => (
                                                                                                <p key={index}>{scheduleData.day?.dayofweek} - {scheduleData.ring?.name}</p>
                                                                                            ))}
                                                                                        </>
                                                                                    )}
                                                                                </IonCol>
                                                                            </IonRow>
                                                                        )}
                                                                    </IonLabel>
                                                                </IonItem>
                                                            ))}
                                                        </div>
                                                        :
                                                        <Spinner />
                                                    }
                                                </>
                                            }
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            )}
                            <IonCol size={showItemsToAdd ? "6" : "12"} id="drop-container" >
                                <IonRow className={showItemsToAdd ? "border-left" : ""}>
                                    <IonCol size="12">
                                        <h4>Current Schedule</h4>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonButton color="light" onClick={() => setShowItemsToAdd(!showItemsToAdd)}>
                                            <IonIcon icon={showItemsToAdd ? chevronForwardOutline : chevronBackOutline} />
                                            {showItemsToAdd ? "Hide Classes + Breaks" : "Show Classes + Breaks"}
                                        </IonButton>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        {(showItemsToAdd || isEditTimes) && (
                                            <>
                                                <IonButton color="success" onClick={handleSaveScheduleItems}>Save Schedule</IonButton>
                                            </>
                                        )}
                                    </IonCol>
                                    <IonCol size="12">
                                        {showItemsToAdd && (
                                            <>
                                                <p className="description text-danger font-weight-bold pb-2">Save your changes before switching day or ring!</p>
                                            </>
                                        )}
                                        {days && <ScheduleDayNavbar days={days} onSelect={handleSelectEventDay} />}
                                        {currentEvent && <SelectEventRing event={currentEvent} isDisplayDefault={true} onSelect={handleSelectEventRing} />}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="text-right" onClick={handleDownloadSchedule}>
                                        {isDownloading ?
                                            <Spinner />
                                            :
                                            <DownloadLink /> 
                                        }
                                    </IonCol>
                                </IonRow>
                                {isLoading ?
                                    <Spinner />
                                    :
                                    <IonRow className="pb-5">
                                        <IonCol className="basic-scrollable">
                                            <Table hover>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Name</th>
                                                        <th>Type</th>
                                                        <th>Time <IonIcon icon={createOutline} ariaLabel="Edit Times" onClick={() => setIsEditTimes(!isEditTimes)} /></th>
                                                        <th>Remove</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(tableTemporaryScheduleItems && tableTemporaryScheduleItems.length > 0) ? 
                                                        <>
                                                            {tableTemporaryScheduleItems.map((item, index) => (
                                                                <tr key={index} id={"table-"+item.id} className={currentAboveItem === index ? "border-bottom-dark-thick" : ""}>
                                                                    <td>{item.number}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.type !== "" ? getEventClassTypeAbbreviation(item.type) : ""}</td>
                                                                    <td>
                                                                        {isEditTimes ? 
                                                                            <TimePicker selectedTime={item.time} onChange={(updatedTime: string) => handleTimeChange(updatedTime, item, index)}/>
                                                                            :
                                                                            <span>{item.time}</span>
                                                                        }
                                                                    </td>
                                                                    <td><IonButton size="small" color="danger" onClick={() => handleRemoveTableItem(item, index)}>X</IonButton></td>
                                                                </tr>
                                                            ))}
                                                            <tr key="table-end">
                                                                <td></td>
                                                                <td>{showItemsToAdd && "Drop a class or break here!"}</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </>
                                                        :
                                                        <tr key="0">
                                                            <td></td>
                                                            <td>{showItemsToAdd && "Drop a class or break here!"}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                        </IonCol>
                                    </IonRow>
                                }
                            </IonCol>
                        </IonRow>
                    </IonCard>
                </IonCol>
            </IonRow>
            <IonRow className="mt-5 mb-5">
                <IonCol>

                </IonCol>
            </IonRow>
        </>
    );
};

export default EventScheduler;