import React, { useState } from "react";
import {
    IonItemGroup,
    IonLabel,
    IonText,
    IonIcon,
    IonCol,
    IonRow,
    IonCard,
    IonCardTitle,
} from "@ionic/react";
import { trash } from "ionicons/icons";
import { OptionGroupsSortedByDiscipline } from "./ClassInfoSection";
import { getFormattedDisciplineString } from "../../utilities/discipline/FormatDisciplines";
import { capitalizeName } from "../../utilities/person/PersonNameFormat";

type SelectedClassesProps = {
    disciplineDataArray: (OptionGroupsSortedByDiscipline[] | null | undefined);
};

const SelectedClasses: React.FC<SelectedClassesProps> = ({ disciplineDataArray }) => {

    const [formattedData, setFormattedData] = useState();
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [expandedSections, setExpandedSections] = useState<Map<number, boolean>>(new Map());

    return (
        <>
            {!disciplineDataArray || disciplineDataArray.length === 0 ? (
                <IonRow>
                    <IonCol className="text-center">
                        <IonText className="text-danger font-weight-bold">
                            No classes selected, class list below.
                        </IonText>
                    </IonCol>
                </IonRow>
            ) : (
                <>
                    {/* Iterate over each discipline group and render a separate card */}
                    {disciplineDataArray.map((disciplineData, index) => (
                        <IonCard key={index} className="rounded-3 mx-0 bg-lighter mb-3">
                            <IonRow className="align-items-center small-scrollable">
                                <IonCol className="p-0">
                                        {/* Discipline Header */}
                                        <IonItemGroup>
                                            <IonRow className="bg-dark px-2 align-items-center">
                                                <IonCol>
                                                <IonCardTitle className='font-weight-bold text-white mt-1 ion-text-wrap'>
                                                    {capitalizeName(getFormattedDisciplineString(disciplineData.disciplineName))}
                                                </IonCardTitle>
                                                </IonCol>
                                            </IonRow>

                                            {/* Iterate over sections within the discipline */}
                                            {(disciplineData.optionGroupArray && disciplineData.optionGroupArray.length > 0) ?
                                                <>
                                                    {disciplineData.optionGroupArray.map((optionGroup, optionGroupIndex) => (
                                                        <IonItemGroup key={optionGroupIndex}>
                                                            {/* Header */}
                                                            {(optionGroup.division && optionGroup.division.isChecked) ? 
                                                                (<>
                                                                    <IonRow className="bg-lighter px-2 align-items-center border-top">
                                                                        <IonCol>
                                                                            <IonLabel className="text-primary font-weight-bold">
                                                                                Division - {optionGroup.division.name}
                                                                            </IonLabel>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    {(optionGroup.classes && optionGroup.classes.length > 0) && (
                                                                        <>
                                                                            {optionGroup.classes.map((cls, classIndex) => (
                                                                                    <IonRow
                                                                                        className="bg-lighter border-top align-items-center px-2"
                                                                                        key={classIndex}
                                                                                    >
                                                                                        <IonCol sizeSm="7" sizeXs="6" className="pl-0">
                                                                                            <IonLabel className="px-0 ion-text-wrap">
                                                                                                {cls.number}. {cls.name}
                                                                                            </IonLabel>
                                                                                        </IonCol>
                                                                                        <IonCol sizeSm="3" sizeXs="3" className="px-0 text-center">
                                                                                            <IonText className="px-0 ion-text-wrap">
                                                                                                DATE HOLDER
                                                                                            </IonText>
                                                                                        </IonCol>
                                                                                        <IonCol sizeSm="1" sizeXs="2" className="px-0 ion-text-center">
                                                                                            <IonText>${cls.entryFee}</IonText>
                                                                                        </IonCol>
                                                                                        <IonCol sizeSm="1" sizeXs="1" className="text-center px-0">
                                                                                            <IonIcon
                                                                                                className="card-icon-size-2 text-danger"
                                                                                                icon={trash}
                                                                                            />
                                                                                        </IonCol>
                                                                                    </IonRow>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                </>)
                                                            :
                                                                (<>
                                                                    {(optionGroup.classes && optionGroup.classes.length > 0) && (
                                                                        <>
                                                                            {optionGroup.classes.map((cls, clsIndex) => (
                                                                                <>
                                                                                    {cls.isChecked && (
                                                                                        <IonRow
                                                                                            className="bg-white border-top align-items-center pl-2"
                                                                                            key={clsIndex}
                                                                                        >
                                                                                            <IonCol sizeSm="7" sizeXs="6" className="px-0">
                                                                                                <IonLabel className="ion-text-wrap">
                                                                                                    {cls.number}. {cls.name}
                                                                                                </IonLabel>
                                                                                            </IonCol>
                                                                                            <IonCol sizeSm="3" sizeXs="3" className="ion-text-center">
                                                                                                <IonText className="px-0 ion-text-wrap">
                                                                                                    DATE HOLDER
                                                                                                </IonText>
                                                                                            </IonCol>
                                                                                            <IonCol sizeSm="1" sizeXs="2" className="ion-text-center">
                                                                                                <IonText>${cls.entryFee}</IonText>
                                                                                            </IonCol>
                                                                                            <IonCol sizeSm="1" sizeXs="1" className="px-0">
                                                                                                <IonIcon
                                                                                                    className="card-icon-size-2 text-danger"
                                                                                                    icon={trash}
                                                                                                />
                                                                                            </IonCol>
                                                                                        </IonRow>
                                                                                    )}
                                                                                </>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                </>)
                                                        
                                                            }

                                                            {/* Stand Alone Classes */}
                                                            {(optionGroup.class && optionGroup.class.isChecked) && (
                                                                <IonRow
                                                                    className="bg-white border-top align-items-center pl-2"
                                                                >
                                                                    <IonCol sizeSm="7" sizeXs="6" className="px-0">
                                                                        <IonLabel className="ion-text-wrap">
                                                                            {optionGroup.class.number}. {optionGroup.class.name}
                                                                        </IonLabel>
                                                                    </IonCol>
                                                                    <IonCol sizeSm="3" sizeXs="3" className="ion-text-center">
                                                                        <IonText className="px-0 ion-text-wrap">
                                                                            DATE HOLDER
                                                                        </IonText>
                                                                    </IonCol>
                                                                    <IonCol sizeSm="1" sizeXs="2" className="ion-text-center">
                                                                        <IonText>${optionGroup.class.entryFee}</IonText>
                                                                    </IonCol>
                                                                    <IonCol sizeSm="1" sizeXs="1" className="px-0">
                                                                        <IonIcon
                                                                            className="card-icon-size-2 text-danger"
                                                                            icon={trash}
                                                                        />
                                                                    </IonCol>
                                                                </IonRow>
                                                            )}
                                                                
                                                        </IonItemGroup>
                                                    ))}
                                                </>
                                                :
                                                <p>No classes found for this discipline.</p>
                                            }
                                        </IonItemGroup>
                                </IonCol>
                            </IonRow>
                        </IonCard>
                    ))}
                </>
            )}

            {/* Total Amount */}
            <IonRow className="px-2 border-top">
                <IonCol>
                    <IonText className="text-primary display-5.5 font-weight-bold">Total</IonText>
                </IonCol>
                <IonCol className="ion-text-right">
                    <IonText className="text-primary display-5.5 font-weight-bold">{totalAmount ? ("$" + totalAmount.toFixed(2)) : "$0"}</IonText>
                </IonCol>
            </IonRow>
        </>
    );
};

export default SelectedClasses;
