import React, { useEffect, useState } from 'react';
import { IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/react';
import { Horse, Event, EventClass, EventDivision } from '../../models';
import { getEventClassesByEventIdWithClassesAndEventDivisions } from '../../utilities/eventClass/EventClass';
import { getEventClassTypeAbbreviation } from '../../utilities/eventClass/EventClassTypes';
import { getEventDivisionById } from '../../utilities/eventDivision/EventDivision';
import Spinner from '../Spinners/Spinner';
import SelectedClasses from "./SelectedClasses";
import SelectClassOrDivisionSection from './SelectClassOrDivisionSection';
import DisciplineCard from './DisciplineCard';

interface Props {
    event: Event;
    selectedHorse?: Horse | null | undefined;
}

export interface formattedOption {
    isExpanded: boolean
    isChecked: boolean
    isDisabled: boolean
    isDivisionEntryOnly: boolean
    isClassEntryOnly: boolean
    entryFee: string
    name: string
    number?: string
    discipline: string
    classType?: string
    note?: string
    value: string
    object: any
}
export interface formattedOptionGroup {
    division?: formattedOption
    classes?: formattedOption[]
    class?: formattedOption
}

export interface OptionGroupsSortedByDiscipline {
    disciplineName: string
    optionGroupArray: formattedOptionGroup[]
}

interface CardData {
    title: "Selected Classes" | "Search All Classes" | "Class List";
    content: JSX.Element;
}

const ClassInfoSection: React.FC<Props> = ({ event, selectedHorse }) => {

    const [eventClasses, setEventClasses] = useState<EventClass[] | undefined | null>();
    const [disciplines, setDisciplines] = useState<OptionGroupsSortedByDiscipline[] | undefined | null>();
    const [disciplinesWithSelectedValues, setDisciplinesWithSelectedValues] = useState<OptionGroupsSortedByDiscipline[] | undefined | null>();
    const [selectedClasses, setSelectedClasses] = useState<any>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function getEventClasses(eventId: string) {
            setIsLoading(true);
            const queryResult = await getEventClassesByEventIdWithClassesAndEventDivisions(eventId);
            if (queryResult.isSuccess) {
                setEventClasses(queryResult.result);
                await formatClassData(queryResult.result);
            }
            setIsLoading(false);
        }

        if (event && (!eventClasses || eventClasses.length === 0)) getEventClasses(event.id);
    }, [event]);

    const findClassInList = (eventClass: EventClass, eventClassList?: EventClass[]) => {
        let result = false;
        if (eventClassList) {
            for (var i = 0; i < eventClassList.length; i++) {
                const currentEventClass = eventClassList[i];
                if (currentEventClass && currentEventClass.id) {
                    if (eventClass.id === eventClassList[i].id) {
                        result = true;
                    }
                } 
            }
        }
        return result;
    }

    const formatClassData = async (eventClasses: EventClass[]) => {
        // optionMap = a way of sorting the divisions and classes so that all classes in a division are grouped together
        // Key = eventDivisionId OR eventClassId
        // Value = formattedOptionGroup
        let optionMap = new Map<string, formattedOptionGroup>();

        // disciplinesMap = a way to group the formattedOptionGroups by similar discipline
        // Key = name of discipline
        // Value = formattedOptionGroup
        let disciplinesMap = new Map<string, formattedOptionGroup[]>();

        for (var i = 0; i < eventClasses.length; i++) {
            // Get the current class and it's label info
            const currentClass: EventClass = eventClasses[i];

            // Get the class type info (i.e. over fences, under saddle, eq on the flat, classic, etc.)
            const classType = (currentClass.class && currentClass.class.type ? (getEventClassTypeAbbreviation(currentClass.class.type)) : "");
            
            // Check for any division info connected to this class - if there is division info, this class will need to be shown with the other classes in the division
            let currentDivision: EventDivision | undefined = undefined;
            const currentDivisionId = currentClass.eventDivision?.id;
            if (currentDivisionId) {
                if (currentClass.eventDivision) {
                    currentDivision = currentClass.eventDivision;
                } else {
                    const queryResult = await getEventDivisionById(currentDivisionId);
                    if (queryResult.isSuccess) {
                        currentDivision = queryResult.result; 
                    }
                }
            }

            if (currentDivision) {
                // Format with Division on top and then classes listed beneath

                // Check the rules of how users can enter the class and/or division
                let canEnterByDivision = ((!!currentDivision.entryFee) && parseFloat(currentDivision.entryFee) > 0);
                let canEnterByClass = ((!!currentClass.entryFee) && parseFloat(currentClass.entryFee) > 0);

                // TO DO - add the logic back in for an entry that has already been saved.
                const entryIsEnrolledInDivision = false; //entry.divisionList?.includes(currentDivision.id);

                // Check to see if this division is already in the OptionMap
                const result: (formattedOptionGroup | null | undefined) = optionMap.get(currentDivision.id);
                if (result) {
                    // Simply update the classes list in the option group
                    const currentClassArray = result.classes || [];
                    const formattedClass: formattedOption = {
                        isExpanded: false,
                        isChecked: findClassInList(currentClass, undefined), //findClassInList(currentClass, currentClassList!), TO DO
                        isDisabled: (canEnterByDivision && !canEnterByClass) ? true : !!(entryIsEnrolledInDivision),
                        isDivisionEntryOnly: (canEnterByDivision && !canEnterByClass),
                        isClassEntryOnly: (!canEnterByDivision && canEnterByClass), 
                        entryFee: currentClass?.entryFee || "",
                        name: currentClass.name,
                        number: currentClass.number ? currentClass.number.toString() : "",
                        discipline: currentClass.class?.discipline || "",
                        classType: classType,
                        note: "",
                        value: currentClass?.id,
                        object: currentClass
                    };
                    let updatedClassArray = currentClassArray.concat([formattedClass]);
                    const updatedFormattedOptionGroup : formattedOptionGroup = {
                        ...result,
                        classes: updatedClassArray
                    }
                    optionMap.set(currentDivision.id, updatedFormattedOptionGroup);
                } else {
                    // Need to format the division info and add it to the OptionMap
                    const formattedDivision: formattedOption = {
                        isExpanded: false,
                        isChecked: !!entryIsEnrolledInDivision,
                        isDisabled: false,
                        isDivisionEntryOnly: (canEnterByDivision && !canEnterByClass),
                        isClassEntryOnly: (!canEnterByDivision && canEnterByClass), 
                        entryFee: currentDivision?.entryFee || "",
                        name: currentDivision.name,
                        number: currentDivision.number ? currentDivision.number.toString() : "",
                        discipline: currentDivision.division?.discipline || "",
                        classType: "",
                        note: "(enter entire division or choose classes in this division)",
                        value: currentDivision?.id,
                        object: currentDivision
                    };
                    
                    const formattedClass: formattedOption = {
                        isExpanded: false,
                        isChecked: findClassInList(currentClass, undefined), //findClassInList(currentClass, currentClassList!), //TO DO
                        isDisabled: (canEnterByDivision && !canEnterByClass) ? true : !!(entryIsEnrolledInDivision),
                        isDivisionEntryOnly: (canEnterByDivision && !canEnterByClass),
                        isClassEntryOnly: (!canEnterByDivision && canEnterByClass), 
                        entryFee: currentClass?.entryFee || "",
                        name: currentClass.name,
                        number: currentClass.number ? currentClass.number.toString() : "",
                        discipline: currentClass.class?.discipline || "",
                        classType: classType,
                        note: "",
                        value: currentClass?.id,
                        object: currentClass
                    };

                    const newFormattedOptionGroup: formattedOptionGroup = {
                        division: formattedDivision,
                        classes: [formattedClass]
                    };

                    optionMap.set(currentDivision.id, newFormattedOptionGroup);
                }
            } else {
                // There is no division for the class - just display the class
                const classType = (currentClass.class && currentClass.class.type ? (getEventClassTypeAbbreviation(currentClass.class.type)) : "");
                const classLabel = currentClass.number + " - " + currentClass.name + " " + classType;
                const formattedClass: formattedOption = {
                    isExpanded: false,
                    isChecked: findClassInList(currentClass, undefined), //findClassInList(currentClass, currentClassList!),
                    isDisabled: false,
                    isDivisionEntryOnly: false,
                    isClassEntryOnly: true, 
                    entryFee: currentClass?.entryFee || "",
                    name: currentClass.name,
                    number: currentClass.number ? currentClass.number.toString() : "",
                    discipline: currentClass.class?.discipline || "",
                    classType: classType,
                    note: "",
                    value: currentClass?.id,
                    object: currentClass
                };
                const newFormattedOptionGroup: formattedOptionGroup = {
                    class: formattedClass
                }; 
                optionMap.set(currentClass.id, newFormattedOptionGroup);
            }
        }

        // Loop through each option group, and sort them by discipline
        optionMap.forEach(formattedOptionGroup => {
            let discipline = "Other"; 
            if (formattedOptionGroup?.division?.discipline) discipline = formattedOptionGroup.division.discipline;
            else if (formattedOptionGroup?.class?.discipline) discipline = formattedOptionGroup.class.discipline;

            // Check if the disciplinesMap already has this discipline
            const disciplineResult: (formattedOptionGroup[] | null | undefined) = disciplinesMap.get(discipline);
            if (disciplineResult) {
                // This discipline is already there, so update its array
                const updatedDisciplineArray = disciplineResult.concat([formattedOptionGroup]);
                disciplinesMap.set(discipline, updatedDisciplineArray);
            } else {
                // Need to add this as a new array for the new discipline
                disciplinesMap.set(discipline, [formattedOptionGroup]);
            }
        });

        // Then loop through the discipline map and format the data into an array
        let disciplinesArray: OptionGroupsSortedByDiscipline[] = [];
        disciplinesMap.forEach((element, key) => {
            const newOption: OptionGroupsSortedByDiscipline = {
                disciplineName: key,
                optionGroupArray: element
            };
            disciplinesArray.push(newOption);
        });

        const sortedArray = disciplinesArray.sort((a, b) => a.disciplineName.localeCompare(b.disciplineName));

        setDisciplines(sortedArray);
        setDisciplinesWithSelectedValues(sortedArray);
    }

    const handleDisciplineFormattedOptionGroupsChange = (index: number, formattedOptionGroups: formattedOptionGroup[]) => {
        if (disciplines) {
            const foundDiscipline = disciplines[index];
            const updatedOptionGroupsSortedByDiscipline = {
                ...foundDiscipline,
                formattedOptionGroups
            };
            const updatedDisciplinesArray = [
                ...disciplines.slice(0, index),
                updatedOptionGroupsSortedByDiscipline,
                ...disciplines.slice(index + 1)
            ];
            setDisciplinesWithSelectedValues(updatedDisciplinesArray);
        } else {
            console.log("No disciplines were found.");
        }
    }

    const handleChangeSearchText = (input: string) => {
        setSearchText(input);
    }

    const cardsData: CardData[] = [
    {
        title: "Selected Classes",
        content: (
            <IonRow>
                <IonCol className='p-0 m-0'>
                    <SelectedClasses disciplineDataArray={disciplinesWithSelectedValues} />
                </IonCol>
            </IonRow>
        ),
    },
    {
        title: "Search All Classes",
        content: (
            <IonRow>
                <IonCol className='p-0 m-0'>
                    <SelectClassOrDivisionSection onChangeSearchText={handleChangeSearchText} />
                </IonCol>
            </IonRow>
        ),
    },
    {
        title: "Class List",
        content: (
            <IonRow className='mt-2'>
                <IonCol className='p-0 m-0'>
                    {(disciplines && disciplines.length > 0) ? 
                        <>
                            {disciplines.map((d, index) => (
                                <span key={index}>
                                    <DisciplineCard 
                                        title={d.disciplineName} 
                                        classList={d.optionGroupArray}
                                        searchText={searchText}
                                        onFormattedOptionGroupsChange={(value: formattedOptionGroup[]) => handleDisciplineFormattedOptionGroupsChange(index, value)}
                                    />
                                </span>
                            ))}
                        </>
                        :
                        <>
                            <p>No classes found.</p>
                        </>
                    }
                </IonCol>
            </IonRow>
        ),
    },
];
    return (
        <>
            <IonRow>
                <IonCol className='p-0 m-0'>
                    <h1 className='font-weight-600 text-info'>Enter Classes | Horse Name {selectedHorse}</h1>
                </IonCol>
            </IonRow>

            {isLoading ?
                <Spinner />
                :
                <>
                    {cardsData.map((card) => (
                        <IonCard key={card.title} className='rounded-3 p-0 mx-0 bg-white' style={{ overflow: "visible" }}>
                            <IonCardHeader className='px-2 py-0 '>
                                <IonRow className='ion-align-items-center'>
                                    <IonCol sizeXs='12' className='px-0 pb-0'>
                                        <IonCardTitle className='font-weight-bold text-primary mt-1'>{card.title}</IonCardTitle>
                                    </IonCol>
                                </IonRow>
                            </IonCardHeader>
                            <IonCardContent className='px-2 pb-2'>{card.content}</IonCardContent>
                        </IonCard>
                    ))}
                </>
            }
        </>
    );
};

export default ClassInfoSection;
