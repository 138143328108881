import React, { useContext, useEffect, useState } from "react";
import {
    IonCard,
    IonCol,
    IonRow,
    IonRadio,
    IonRadioGroup,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    IonChip,
    IonModal, 
    IonButton
} from "@ionic/react";
import { Barn, BarnMember, Contact, Event } from "../../../models";
import { trash, close } from "ionicons/icons";
import Spinner from "../../Spinners/Spinner";
import SelectBarnFromAllBarns from "../../Barn/SelectBarnFromAllBarns";
import { formatPhoneNumberForDisplay } from "../../../utilities/contact/FormatPhoneNumber";
import { PersonContext } from "../../../context/PersonContext";
import { createBarnMember, deleteBarnMember, getBarnMembersByPersonId } from "../../../utilities/barnMember/BarnMember";
import { CreateBarnMemberInput } from "../../../API";
import { getAddressById } from "../../../utilities/address/Address";
import { getContactById } from "../../../utilities/contact/Contact";
import { formatAddress } from "../../../utilities/address/FormatAddress";
import ErrorBanner from "../../Banners/ErrorBanner";

interface _Props {
    event: Event;
    onSelect: Function;
}

interface FormattedBarnInfo {
    id: string;
    barn: Barn;
    addressString: string;
    contactString: string;
    websiteString?: string;
    barnMembership?: {
        id: string;
        permissionLevel: string | null;
    } | null;
}

const SelectBarnSection: React.FC<_Props> = ({onSelect}) => {
    const user = useContext(PersonContext);
    const [selectedBarn, setSelectedBarn] = useState<Barn | null>(null);
    const [formattedBarns, setFormattedBarns] = useState<FormattedBarnInfo[]>([]);
    const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [modalBarn, setModalBarn] = useState<Barn | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string>("");
    
    useEffect(() => {
        if (user) {
            fetchBarnMemberships();
        }
    }, [user]);

    const fetchBarnMemberships = async (): Promise<void> => {
        setIsLoading(true);
    
        try {
            const barnMembershipsResult = await getBarnMembersByPersonId(user.id);
    
            if (barnMembershipsResult.isSuccess) {
                const barnMembers: BarnMember[] = barnMembershipsResult.result;

                // Alphabetize barnMembers by barn name with case insensitivity
                barnMembers.sort((a, b) => a.barn.name.localeCompare(b.barn.name, undefined, { sensitivity: 'base' }));
                
                const barns = barnMembers.map((barnMember) => barnMember.barn);
    
                const formattedBarnsPromises = barnMembers.map(async (barnMember) => {
                    return await formatBarn(barnMember.barn, barnMember);
                });
                const formattedBarns = await Promise.all(formattedBarnsPromises);
    
                setFormattedBarns(formattedBarns);
    
                // Set the selected barn to the top if it exists
                const selectedBarn = barns[0];
                setSelectedBarn(selectedBarn);
                onSelect(selectedBarn);
                setIsLoading(false);
            } else {
                throw new Error("Failed to fetch barn memberships.");
            }
        } catch (error) {
            setError("Sorry, a problem occurred while finding barns that you’re a member of.");
        } finally {
            setIsLoading(false);
        }
    };
    
    const formatBarn = async (barn: Barn, barnMember: BarnMember | null = null): Promise<FormattedBarnInfo> => {
        let addressString = "Address not provided",
            contactString = "Contact not provided",
            websiteString = "Website not provided";
    
        try {
            if (barn.barnLocationId) {
                const addressResult = await getAddressById(barn.barnLocationId);
                if (addressResult.isSuccess) {
                    const address = addressResult.result;
                    addressString = formatAddress(address);
                }
            }
    
            if (barn.barnContactId) {
                const contactResult = await getContactById(barn.barnContactId);
                if (contactResult.isSuccess) {
                    const contact: Contact = contactResult.result;
                    if (contact.work) contactString = formatPhoneNumberForDisplay(contact.work);
                    if (contact.website) websiteString = contact.website;
                }
            }
        } catch (error) {
            setError("An error occurred while formatting barn information.");
        }
    
        return {
            id: barn.id,
            barn,
            addressString,
            contactString,
            websiteString,
            barnMembership: barnMember ? {
                id: barnMember.id,
                permissionLevel: barnMember.permissionLevel || null,
            } : null,
        };
    };
    
    const handleBarnSelection = async (barn: Barn | null | undefined) => {
        setIsLoading(true);

        if (!barn) {
            setIsLoading(false);
            onSelect(null);
            return;
        }

        try {
            // Check if the barn is already in the formattedBarns list
            const isBarnInList = formattedBarns.some(b => b.barn.id === barn.id);

            if (!isBarnInList) {
                const formattedBarn = await formatBarn(barn);

                setFormattedBarns(prevBarns => {
                    // Remove any existing entry for the barn (in case it exists from a previous selection)
                    const updatedBarns = prevBarns.filter(b => b.barn.id !== barn.id);
                    // Add the new formatted barn to the top of the list
                    const newBarns = [formattedBarn, ...updatedBarns];
                    setIsLoading(false);
                    return newBarns;
                });
            } else {
                // If the barn is already in the list, move it to the top
                setFormattedBarns(prevBarns => {
                    const updatedBarns = prevBarns.filter(b => b.barn.id !== barn.id);
                    const newBarns = [formattedBarns.find(b => b.barn.id === barn.id)!, ...updatedBarns];
                    setIsLoading(false);
                    return newBarns;
                });
            }

            setSelectedBarn(barn);
            onSelect(barn);
        } catch (error) {
            setError("An error occurred while handling barn selection.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleRadioButtonClick = (barn: Barn) => {
        setSelectedBarn(barn);
        onSelect(barn);
    }
    
    const openJoinModal = (barn: Barn) => {
        setModalBarn(barn);
        setIsJoinModalOpen(true);
    };

    const openDeleteModal = (barn: Barn) => {
        setModalBarn(barn);
        setIsDeleteModalOpen(true);
    }

    const closeJoinModal = () => setIsJoinModalOpen(false);
    const closeDeleteModal = () => setIsDeleteModalOpen(false);

    const addMembership = async () => {
        setIsLoading(true);
    
        if (!modalBarn) {
            setError("No barn found.");
            setIsLoading(false);
            return;
        }
    
        try {
            const newMemberInfo: CreateBarnMemberInput = {
                name: `${user.firstName} ${user.lastName}`,
                personId: user.id,
                barnId: modalBarn.id,
                permissionLevel: "member",
                roles: user.roles,
            };
    
            const { isSuccess, result } = await createBarnMember(newMemberInfo);
    
            if (isSuccess && result?.id) {
                // Update the specific barn's membership information with the new barnMembership Id
                setFormattedBarns(prevBarns =>
                    prevBarns.map(barn =>
                        barn.id === modalBarn.id
                            ? {
                                ...barn,
                                barnMembership: {
                                    id: result.id,  // Use the barnMembership Id from the result
                                    permissionLevel: "member",
                                },
                            }
                            : barn
                    )
                );
            } else {
                setError("Error: could not create the request.");
            }
    
            setIsJoinModalOpen(false);
        } catch (error) {
            setError("An error occurred while joining the barn.");
        } finally {
            setIsLoading(false);
        }
    };
     
    const deleteMembership = async () => {
        setIsLoading(true);
    
        if (!modalBarn) {
            setError("No barn found.");
            setIsLoading(false);
            return;
        }
    
        try {
            // Find the barnMember that matches the chosen barn
            const barnMember = formattedBarns.find(barn => barn.id === modalBarn.id)?.barnMembership;
    
            if (!barnMember || !barnMember.id) {
                setError("No barn membership found.");
                setIsLoading(false);
                return;
            }
    
            const barnMembershipInfo = { id: barnMember.id };
    
            // Delete Barn membership using the provided deleteBarnMember function
            const result = await deleteBarnMember(barnMembershipInfo);
    
            if (result.isSuccess) {
                setError("");
                setSelectedBarn(null);
                // Update the barn memberships to ensure the deleted item is removed
                setFormattedBarns(prevBarns => prevBarns.filter(b => b.barn.id !== modalBarn.id));
            } else {
                setError("Failed to delete membership.");
            }
            
        } catch (error) {
            setError("An error occurred while deleting the membership.");
        } finally {
            setIsDeleteModalOpen(false);
            setIsLoading(false);
        }
    };
    
    return (
        <>
            {/* Section Title */}
            <IonRow>
                <IonCol className="p-0">
                    <h1 className="font-weight-bold text-info">Select a Barn</h1>
                </IonCol>
            </IonRow>

            {/* Barn Search Bar */}
            <IonRow>
                <IonCol className="p-0">
                    <SelectBarnFromAllBarns onSelect={handleBarnSelection} placeholder="Search barns..." />
                </IonCol>
            </IonRow>

            {/* List of Barns */}
            {error && (
                <IonRow>
                    <IonCol>
                        <ErrorBanner error={error} />
                    </IonCol>
                </IonRow>
            )}
            
            {!isLoading && formattedBarns.length === 0 && (
                <p className="text-danger">You’re not currently a member of any barns.</p>
            )}

            <IonRow>
                <IonCol className="p-0">
                    {isLoading ? (
                       <IonRow>
                            <IonCol>
                                <Spinner />
                            </IonCol>
                        </IonRow> 
                   
                   ) : (
                        <IonRadioGroup value={selectedBarn?.id || formattedBarns[0]?.id}>
                            {formattedBarns.map(({ id, barn, addressString, contactString, websiteString, barnMembership }) => (
                                <IonCard key={id} className="rounded-3 p-0 mx-0 bg-white">
                                    
                                    {/* Radio Button + Stable Name */}
                                    <IonCardHeader className="px-2 pb-0">
                                        <IonRow className="ion-align-items-center">
                                            <IonCol sizeXs="3" sizeSm="2" className="ion-text-center text-primary pl-0 pb-0">
                                                <IonRadio
                                                    value={id}
                                                    onClick={() => handleRadioButtonClick(barn)}
                                                />
                                            </IonCol>
                                            <IonCol sizeXs="9" sizeSm="10" className="px-0 pb-0">
                                                <IonCardTitle className="font-weight-bold text-primary mb-1">{barn.name}</IonCardTitle>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardHeader>

                                    {/* Barn Info */}
                                    <IonCardContent className="px-3">
                                        <IonRow>
                                            <IonCol className="p-0">
                                                <p className="m-0">{addressString}</p>
                                                <p className="m-0">{contactString}</p>
                                                {websiteString !== "Website not provided" ? (
                                                    <p className="m-0">
                                                        <a href={websiteString} target="_blank" rel="noopener noreferrer">
                                                            {websiteString}
                                                        </a>
                                                    </p>
                                                ) : (
                                                    <p className="m-0">Website not provided</p>
                                                )}
                                            </IonCol>
                                        </IonRow>

                                        {/* Chip + Trash Icon */}
                                        <IonRow className="ion-justify-content-between ion-align-items-center">
                                            <IonCol className="mt-1 p-0">
                                                {(barnMembership != null && (barnMembership.permissionLevel == "member" || barnMembership.permissionLevel == "admin")) ? (
                                                    <IonChip className="rounded-2 font-weight-bold bg-info text-white mx-0">Member</IonChip>
                                                ) : (
                                                    <IonChip className="rounded-2 font-weight-bold bg-primary text-white mx-0" onClick={() => openJoinModal(barn)}>
                                                        + Become a Member
                                                    </IonChip>
                                                )}
                                            </IonCol>
                                            <IonCol size="2" className="ion-text-right p-0">
                                                <IonIcon
                                                    className="card-icon-size-2 text-danger pt-1"
                                                    icon={trash}
                                                    onClick={() => {
                                                        if (barnMembership && (barnMembership.permissionLevel === "member" || barnMembership.permissionLevel === "admin")) {
                                                            openDeleteModal(barn); // Open the delete modal if the user is a member
                                                        } else {
                                                            // Remove the barn from the displayed list if the user is not a member
                                                            setFormattedBarns(prevBarns => prevBarns.filter(b => b.barn.id !== barn.id));
                                                        }
                                                    }}
                                                />
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            ))}
                        </IonRadioGroup>
                    )}
                </IonCol>
            </IonRow>

            {/* Modal: Become a Member */}
            <IonModal isOpen={isJoinModalOpen} onDidDismiss={closeJoinModal}>
                <IonRow>
                    <IonCol>
                        <IonRow className="ion-align-start">
                            <IonCol className="ion-text-right">
                                <IonButton fill="clear" onClick={closeJoinModal}>
                                    <IonIcon className="card-icon-size-1" icon={close} />
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-justify-content-start">
                            <IonCol size="10" offset="1" className="ion-text-center p-0 m-0 mt-2">
                                <IonCardTitle className="display-3 text-info">Become a Member</IonCardTitle>
                                {modalBarn && (
                                    <IonCardSubtitle className="display-4 text-medium">
                                        Are you sure you want to join {modalBarn.name}?
                                    </IonCardSubtitle>
                                )}
                                <p>Joining this barn will share information about yourself and your horse(s).</p>
                                <p>As a member, you'll have access to this barn's information such as trainers, owners, and riders to speed up future submissions.</p>
                                <IonRow>
                                    <IonCol className="ion-text-center">
                                        <IonButton color="medium" onClick={closeJoinModal}>Cancel</IonButton>
                                        <IonButton color="success" onClick={addMembership}>Join Barn</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonModal>

            {/* Modal: Delete Membership */}
            <IonModal isOpen={isDeleteModalOpen} onDidDismiss={closeDeleteModal}>
                <IonRow>
                    <IonCol>
                        <IonRow className="ion-align-start">
                            <IonCol className="ion-text-right">
                                <IonButton fill="clear" onClick={closeDeleteModal}>
                                    <IonIcon className="card-icon-size-1" icon={close} />
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-justify-content-start">
                            <IonCol size="10" offset="1" className="p-0 m-0 mt-2">
                                {modalBarn ? (
                                    <>
                                        <IonCardTitle className="ion-text-center display-3 text-info">
                                            Remove Membership to {modalBarn.name}?
                                        </IonCardTitle>
                                        <IonCardSubtitle className="ion-text-start display-4 text-medium">
                                            Doing this will...
                                        </IonCardSubtitle>
                                        <ol>
                                            <li>Revoke access to this barn's information such as trainers, owners, and riders.</li>
                                            <li>Unshare all information about yourself and your horse(s).</li>
                                            <li>Make future submissions slower by disabling auto-fill.</li>
                                        </ol>
                                    </>
                                ) : (
                                    <IonCardTitle className="ion-text-center display-3 text-danger">
                                        No barn selected
                                    </IonCardTitle>
                                )}
                                <IonRow>
                                    <IonCol className="ion-text-center">
                                        <IonButton color="medium" onClick={closeDeleteModal}>Cancel</IonButton>
                                        <IonButton color="danger" onClick={deleteMembership} disabled={!modalBarn}>Remove</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonModal>

        </>
    );
};

export default SelectBarnSection;