import React, { useState } from 'react';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonRow, IonCol, IonIcon } from '@ionic/react';
import { capitalizeName } from '../../utilities/person/PersonNameFormat';
import ClassList from './ClassList';
import { chevronDown, chevronUp } from "ionicons/icons";
import { formattedOptionGroup } from './ClassInfoSection';
import { getFormattedDisciplineString } from '../../utilities/discipline/FormatDisciplines';

interface DisciplineCardProps {
  title: string;
  classList: formattedOptionGroup[];  // classList data to be passed directly
  searchText: string;
  onFormattedOptionGroupsChange: Function; // Prop to handle checkbox changes
}

const DisciplineCard: React.FC<DisciplineCardProps> = ({ title, classList, searchText, onFormattedOptionGroupsChange }) => {
  const [expandedCard, setExpandedCard] = useState<boolean>(false);

  const toggleCardExpansion = () => {
    setExpandedCard((prev) => !prev);
  };

  const handleFormattedOptionGroupsChange = (value: formattedOptionGroup[]) => {
    onFormattedOptionGroupsChange(value);
  }

  return (
    <IonRow>
      <IonCol className="p-0 m-0">
        <IonCard className="rounded-3 pb-0 mx-0 my-1 bg-dark">
          <IonCardHeader className="px-2 py-0" onClick={toggleCardExpansion}>
            <IonRow className="ion-align-items-space-between">
              <IonCol size="10" className="px-0 ion-text-left">
                <IonCardTitle className="font-weight-bold text-white m-1 ion-text-wrap">
                  {capitalizeName(getFormattedDisciplineString(title))} {/* Display the discipline name */}
                </IonCardTitle>
              </IonCol>
              <IonCol size="2" className="px-0 pt-2 ion-text-right">
                <IonIcon
                  icon={expandedCard ? chevronUp : chevronDown}
                  className="text-white display-6"
                  style={{ fontSize: '24px', cursor: 'pointer' }}
                />
              </IonCol>
            </IonRow>
          </IonCardHeader>

          {expandedCard && (
            <IonCardContent className="p-0">
              <ClassList formattedOptionGroups={classList} searchText={searchText} onChange={handleFormattedOptionGroupsChange} />
            </IonCardContent>
          )}
        </IonCard>
      </IonCol>
    </IonRow>
  );
};

export default DisciplineCard;
